<div class="creditsnap-radio">
    <div class="input_box" *ngIf="radioArray">
        <mat-radio-group class="d-flex flex-column align-items-center" [(ngModel)]="radioGroupModel" [name]="radioArray.groupName" (change)="sendValue()">

            <ng-container *ngIf="radioArray.subProductTemplate === RadioTemplate.RadioCard">
                <ng-container *ngFor="let radio of radioArray.items; let i=index">
					<mat-radio-button class="radio-card-template" [class.disable]="radio.disabled" [class.visually-hidden]="radio.hidden"
									  [value]="radio.value" [disabled]="radio.disabled" [id]="radio.value+i">
						<mat-label class="d-flex">
							<div class="input_box-left" *ngIf="radio.image">
								<img class="img-fluid" [src]="radio.image" alt="icon credit card">
							</div>
							<div class="input_box-left icon" *ngIf="radio.icon">
								<mat-icon class="fs-2">{{radio.icon}}</mat-icon>
							</div>
							<div class="input_box-right text-left text-start">
								<h2 [innerHTML]="radio.heading || 'Headline'"></h2>
								<p *ngIf="radio.shortDescription" [innerHTML]="radio.shortDescription || 'Description'"></p>
								<p *ngIf="radio.extraInfo" class="extra_txt w-100" [innerHTML]="radio.extraInfo"></p>
								<p *ngIf="radio.bonusInfo" class="offer_txt" [innerHTML]="radio.bonusInfo"></p>
								<div class="d-flex balance-details">
									<div class="d-flex" *ngIf="radio.minBalance">
										<p class="w-100 pr-1">Minimum balance: </p>
										<p>{{radio.minBalance}}</p>
									</div>
									<div class="d-flex ml-3" *ngIf="radio.apy">
										<p class="w-100 pr-1">APY: </p>
										<p>{{radio.apy}}</p>
									</div>
								</div>


								<div class="learn_more-box" [class.active]="radio.learnMoreActive" *ngIf="radio.learnMore">
									<a *ngIf="radio.learnMore" class="learn_more" id="learn_more_radio" href="javascript:void(0);" (click)="activeLearnMore(i)">Learn more</a>
									<div class="learn_more-txt">
										<p [innerHTML]="radio.learnMore"></p>
									</div>
								</div>
								<div *ngIf="radio.description" class="learn_more-txt">
									<p [innerHTML]="radio.description"></p>
								</div>
								<div class="input_wrap search" *ngIf="radio.isSearchData">
									<div class="field_wrap">
										<div class="m--margin-bottom-10-mobile">
											<mat-form-field class="mat-form-field-fluid search-field">
												<mat-icon matPrefix class="search-field-icon">search</mat-icon>
												<input matInput type="text" [(ngModel)]="searchSelectedValue[i]" class="searchable-input"
													   [disabled]="radio.value !== radioGroupModel" (keyup)="sendSearchValue($event, radio.value, i)"
													   autocomplete="off" required name="firstName" [placeholder]="radio.search.label">
											</mat-form-field>
											<ng-container *ngIf="searchSelectedValue[i] && searchSelectedValue[i].length > 2 && !selectionComplete[i]">
												<p class="search_results" *ngIf="radio.search.loading; else searchOptionList">
													Searching through results ...
												</p>
												<ng-template #searchOptionList>
													<p class="search_results"
													   *ngIf="radio.search && radio.search.searchValues && radio.search.searchValues.length; else noResults">
                                                <span class="result" *ngFor="let result of radio.search.searchValues" [attr.data-value]="result.id"
													  [innerHTML]="highlightedText(result.title, radio.search.value)" (click)="selectSearchValue(result, i)">
                                                </span>
													</p>

													<ng-template #noResults>
														<p class="search_results">
															<span>No results found, try with different search.</span>
														</p>
													</ng-template>
												</ng-template>
											</ng-container>
										</div>
									</div>
								</div>
								<div class="radio-text-box">
									<mat-form-field *ngIf="radio.heading === 'Other' && radio.otherText.optional">
										<mat-label>Purpose Description</mat-label>
										<input matInput type="text" name="Other" alphabetWithSpace [(ngModel)]="radio.otherText.value"
											   [disabled]="radio.value !== radioGroupModel" [required]=" radio.value === radioGroupModel">
										<mat-error>Purpose Description is <strong>required.</strong></mat-error>
									</mat-form-field>
								</div>
							</div>
						</mat-label>
					</mat-radio-button>
				</ng-container>
            </ng-container>

            <ng-container *ngIf="radioArray.subProductTemplate === RadioTemplate.RadioList">
                <mat-radio-button class="radio-list-template text-left" *ngFor="let radio of radioArray.items; let i=index" [value]="radio.value" [id]="radio.value+i">
                    <mat-label class="d-sm-flex" [innerHTML]="radio.heading || 'Headline'">
                    </mat-label>
                </mat-radio-button>
            </ng-container>
        </mat-radio-group>
    </div>
</div>
