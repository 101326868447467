import {
	AfterViewInit, ChangeDetectorRef,
	Component,
	EventEmitter,
	Inject,
	Input, OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation
} from '@angular/core';
import {
	APP_CONFIG,
	APP_ENV_CONFIG,
	APP_TEXT_CONFIG,
	SharedKeyDataService
} from '@creditsnap/app-config';
import * as _ from 'lodash';
import {RadioOptionModel} from '../radio-group/radio-option.model';
import {
	ApplicationModel,
	KeyIdentifierModel,
	RadioTemplate
} from '@creditsnap/data-models';
import {MatStepper} from '@angular/material/stepper';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
	selector: 'creditsnap-product-selection',
	templateUrl: './product-selection.component.html',
	styleUrls: ['./product-selection.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ProductSelectionComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('productSelectionStepper') productSelectionStepper: MatStepper;
	@Input() navigationHidden = false;
	@Output() productSelectionComplete: EventEmitter<void> = new EventEmitter<void>();
	@Output() selectedProduct = new EventEmitter();

	subscription: Subscription;
	keyIdentifier: KeyIdentifierModel;
	loanPurposesByType: any = {};
	loanPurposes: any = [];

	productTypeListRadioObject: RadioOptionModel = new RadioOptionModel();
	selectedProductCategory: any;

	productListRadioObject: RadioOptionModel;
	selectedProductData: any;
	skipProductStep = false;
	skipSubProductStep = false;

	subProductListRadioObject: RadioOptionModel;
	selectedSubProductData: any;

	constructor(@Inject(APP_CONFIG) public appConfig: any,
				@Inject(APP_ENV_CONFIG) public environment: any,
				@Inject(APP_TEXT_CONFIG) public appTextConfig: any,
				private cdr: ChangeDetectorRef,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				private sharedKeyDataService: SharedKeyDataService) {
		this.subscription = this.sharedKeyDataService.keyIdentifier$
			.subscribe(async (data) => {
				this.keyIdentifier = data;
				this.keyIdentifier.loanPurposeConfig = this.appConfig.loanPurpose[this.keyIdentifier.loanPurpose];
			});
	}

	ngOnInit(): void {
		_.forEach(this.appConfig.loanPurpose, (value: any) => {
			// eslint-disable-next-line no-prototype-builtins
			// if (!value.hasOwnProperty('hideFromDropdown') || !value.hideFromDropdown) {
				this.loanPurposes.push(value);
			// }
		});
		this.loanPurposesByType = _.groupBy(this.loanPurposes, 'productName');

		/* ========== Set Product Category List array ========== */
		this.productTypeListRadioObject.groupName = 'product-type';
		this.productTypeListRadioObject.items = [];
		Object.keys(this.loanPurposesByType).forEach((type: string) => {
			console.log('this.appConfig.loanCategories >>>>>>>>', this.appConfig.loanCategories);
			if(!this.appConfig.loanCategories[type]) {
				return;
			}
			this.productTypeListRadioObject.items.push({
				heading: this.appConfig.loanCategories[type].name,
				name: type,
				value: type,
				id: type,
				description: this.appConfig.loanCategories[type].description || '',
				icon : this.appConfig.loanCategories[type].icon,
			});
		});
	}

	ngAfterViewInit() {
		this.activatedRoute.params.subscribe(params => {
			const loanPurposeData = params['loanPurpose'] || this.keyIdentifier.loanPurpose;
			if(loanPurposeData) {
				const loanPurpose = loanPurposeData.toUpperCase();
				if (loanPurpose && this.appConfig.loanPurpose[loanPurpose]) {
					const product = this.appConfig.loanPurpose[loanPurpose];
					this.changeProductCategory({value: product.productName})
					this.updateLoanPurposeConfig(loanPurpose);
					this.productSelectionStepper.next();

					const productIndex = this.loanPurposes.findIndex(item => item.id === loanPurpose);
					const productData = {
						id: productIndex,
						value: loanPurpose,
						name: loanPurpose,
						heading : product.title,
					}
					this.changeProductSelection(productData);

					setTimeout(() => {
						this.productSelectionStepper.next();

						if(this.appConfig.loanPurpose[loanPurpose] &&
							((!this.appConfig.loanPurpose[loanPurpose].purposeCodeList) || this.appConfig.loanPurpose[loanPurpose].purposeCodeList.length <= 0)) {

							this.sharedKeyDataService.stepperStep.next(true);
							this.router.navigate(['/identity-verification']);
						} else {
							this.productSelectionStepper.next();
						}
					}, 500);
				} else {
					this.keyIdentifier.loanPurpose = undefined;
					this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
				}
			}

			if(params['productName']) {
				const productName = params['productName'].toUpperCase()
				if (productName && this.loanPurposesByType[productName]) {
					this.changeProductCategory({value: productName})
					this.productSelectionStepper.next();
				}
			}

			if(params['referralCode']) {
				this.keyIdentifier.referralCode = params['referralCode'];
				this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
			}
		});

		// Disable animation to get the correct step length
		this.productSelectionStepper.disableRipple = true;
	}

	/**
	 * Product category selection
	 * @param category
	 */
	changeProductCategory(category: any) {
		this.selectedProductCategory = category;
		/* ========== Set Product List array  based on category selection ========== */
		this.productListRadioObject = new RadioOptionModel();
		this.productListRadioObject.subProductTemplate = RadioTemplate.RadioCard;

		Object.keys(this.loanPurposes).forEach((loanPurposeId: string) => {
			const loanPurpose = this.loanPurposes[loanPurposeId];
			if(loanPurpose.productName === category.value) {
				this.productListRadioObject.items.push({
					heading: loanPurpose.title,
					name: loanPurpose.id,
					value: loanPurpose.id,
					id: loanPurposeId,
					description: loanPurpose.description || '',
					icon : loanPurpose.icon,
					hidden: loanPurpose.hideFromDropdown
				});
			}
		});
		console.log('loanPurpose >>>>>>>>>>>>>>>', this.productListRadioObject);
	}

	goToProductSelection() {
		const filteredProductList = this.productListRadioObject.items.filter((product: any) => !product.hidden);
		if(filteredProductList.length === 1) {
			this.skipProductStep = true;
			this.changeProductSelection(filteredProductList[0]);
			this.submitProductSelection();
		} else {
			this.productSelectionStepper.next();
		}
	}

	updateLoanPurposeConfig(loanPurpose: any) {
		this.keyIdentifier.loanPurpose = loanPurpose;
		this.keyIdentifier.loanPurposeConfig = this.appConfig.loanPurpose[loanPurpose];
		this.keyIdentifier.isCashApplication = this.keyIdentifier.loanPurposeConfig.isCashApplication;
		this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
		// this.setButtonText();
	}

	/**
	 * Product selection change
	 * @param product
	 */
	changeProductSelection(product: any) {
		this.selectedProductData = product;
		const productData = this.loanPurposes[product.id]
		this.skipSubProductStep = !(productData.purposeCodeList && productData.purposeCodeList.length);

		this.updateLoanPurposeConfig(product.value);
		if(!this.keyIdentifier.application) {
			this.keyIdentifier.application = new ApplicationModel();
		}
		this.keyIdentifier.application.productName = product.value;
		this.keyIdentifier.loanPurpose = product.value;
		if(productData.purposeCodeList && productData.purposeCodeList.length) {
			/* ========== Set Sub Product List array based on category selection ========== */
			this.subProductListRadioObject = new RadioOptionModel();
			this.subProductListRadioObject.subProductTemplate = RadioTemplate.RadioCard;

			const subProductList = productData.purposeCodeList;
			subProductList.forEach((subProduct: {value: string, label: string}) => {
				this.subProductListRadioObject.items.push({
					heading: subProduct.label,
					name: subProduct.value,
					value: subProduct.value,
					id: subProduct.value,
					otherText: {optional : subProduct.label === 'Other',
						value: ''
					},
				});
			});
		}
	}

	/**
	 * Sub Product selection change
	 * @param subProduct
	 */
	changeSubProductSelection(subProduct: any) {
		this.selectedSubProductData = subProduct;
		this.keyIdentifier.personalLoanPurposeCode = subProduct.name;

		if (subProduct.name.includes('^')) {
			const concatenatedValue = subProduct.name.split('^');
			this.updateLoanPurposeConfig(concatenatedValue[0]);
			this.keyIdentifier.personalLoanPurposeCode = concatenatedValue[1] === 'Other' ? subProduct.otherText.value : concatenatedValue[1];
		}
	}

	/**
	 * Submit and go to Personal Information step of main stepper
	 */
	submitProductSelection() {
		this.cdr.detectChanges();
		if(this.subProductListRadioObject?.items?.length === 1 && !this.selectedSubProductData) {
			this.changeSubProductSelection(this.subProductListRadioObject.items[0]);
			this.submitProductSelection();
		} else {
			const isLastStep = this.productSelectionStepper.selectedIndex === this.productSelectionStepper.steps.length - 1;
			if (isLastStep) {
				this.sharedKeyDataService.announceIdentifierChanges(this.keyIdentifier);
				this.selectedProduct.emit(this.loanPurposes[this.selectedProductData.id]);
				this.productSelectionComplete.emit();
			} else {
				// if subProduct selection is remaining
				this.productSelectionStepper.next();
			}
		}
	}

	ngOnDestroy() {
		if(this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
