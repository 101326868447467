import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CSPostResponseModel} from "@creditsnap/data-models";
import {APP_CONFIG, APP_ENV_CONFIG} from "../index";


export class otpPostRequestBody {
	channel?: string;
	contact?: string;
	email?: string;
	applicationId?: number;
	blockListFlow?:boolean;
	searchOtpRequired?: boolean;
	lastName?: string;
	dateOfBirth?: Date;
	dob?: string;
	reEntry?: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class OtpService {

	channel: string;

	constructor(private http: HttpClient,
				@Inject(APP_ENV_CONFIG) private environment: any,
				@Inject(APP_CONFIG) private appConfig: any) {
		this.channel = this.appConfig.otpVerifyChannel;
	}

	sendOTP(postBody: otpPostRequestBody): Observable<CSPostResponseModel> {
		if(!postBody.channel) {
			postBody.channel = this.appConfig.otpVerifyChannel;
		}
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/otp/send`, postBody,
			{headers: {'X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader}});
	}

	verifyOTP(code: string = '', contact: string = '', email: string = ''): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/otp/verify`, {
				channel: this.channel,
				contact,
				email,
				code
			},
			{headers: {'Channel': 'TELLER','X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader}});
	}

	verifyOTPV1(applicationId: number, code: string = '', contact: string = '', email: string = ''): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/application/otp/verify/${applicationId}`, {
				channel: this.channel,
				contact,
				email,
				code
			});
	}

	searchVerifyOTP(dateOfBirth: Date, lastName: string = '', code: string = '', contact: string = '', email: string = '', dob: string =''): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/search/otp/verify`, {
			dateOfBirth,
			lastName,
			code,
			contact,
			email,
			dob,
			channel: this.channel
		},
		{headers: {'Channel': 'TELLER','X-Skip-Interceptor': this.environment.auth.interceptorSkipHeader}});
	}
}
