import { SearchSystem } from '../enums/process-state.enum';

export class SearchModel {
    dob: string;
    dateOfBirth: Date;
    last4SSN: string;
    email: string;
    zipCode: string;
    lastName: string;
    productType: string;
    productName: string;
    offerCode: string;
    code: string;
	primaryPhone: string;
	ssn: string;
	encryptedKey: string;
	searchSystem: SearchSystem;
}
