import {Directive, ElementRef, HostListener} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator} from "@angular/forms";

@Directive({
    selector: 'input[alphabetOnly]',
	providers: [{ provide: NG_VALIDATORS, useExisting: AlphabetDirective , multi: true}]
})
export class AlphabetDirective implements Validator {

    constructor(private _el: ElementRef) {
    }

	validate(control: AbstractControl): { [key: string]: any } {
		const isValid = !control.value || (!control.value.startsWith(' ') && !control.value.endsWith(' '));
		return isValid ? null : { 'whitespace': true };
	}

    @HostListener('input', ['$event']) onInputChange(event: any) {
		if(this._el.nativeElement.value) {
			this._el.nativeElement.value = this._el.nativeElement.value.trim();
		}
        const initalValue = this._el.nativeElement.value;
		if(initalValue) {
			this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z-' ]/g, '').trim();
		}
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }
}
