import {OfferModel} from './offer.model';
import {ApplicantModel} from './applicant.model';
import {CollateralModel} from './collateral.model';
import {FinanceModel} from './finance.model';
import {CustomerConsentModel} from './customer-consent.model';
import {AppConfigService, getDeviceType} from "@creditsnap/app-config";
import {ExtendedAttributesModel} from './extendedAttributes.model';

export class ApplicationModel {
	applicationId: number;
	preApprovalApplicationId: number;
	clientApplicationNumber: string;
	externalApplicationId: string;
	applicationType: string; // J - Joint, I - Individual
	applicationStatus: string;
	offerStatus: string; // helps to understand whether offer is generated or not
	collateralStatus: string; // helps to understand whether collateral is added or not
	applicantsStatus: string; // helps to understand whether applicants added or not
	status: string;
	statusShortName: string;
	productName: string;
	loanPurpose: string; // AR - Refinance, AL - Lease Buyout, AF - Fleet Buyout, AN - New Auto Purchase,
	// AU - Used Auto Purchase; HE - Home Equity, HM - Mortgage
	productType: string; // A - Auto,  F - Fleet, H - Home Equity, P - Personal, C - Cash, M - Mortgage
	stipulations: string;
	loanAmount: number | null;
	ach: boolean;
	emi: number;
	apr: number;
	term: number | null;
	expireDate: Date;
	appDate: Date;
	offers: OfferModel[];
	applicants: ApplicantModel[];
	collateral: CollateralModel;
	finance: FinanceModel;
	partnerId: string; // SAAS customer partner number
	leadId: string; // Unique customer identifier coming from SaaS customer lead identifier
	referralCode: string; // this is our SaaS customer's lead partner name or description.
	employeeId: string;
	subID: string; // SAAS customer partner lead Id
	channel: string;
	activity: string;
	activityShortDesc: string;
	consents: CustomerConsentModel[];
	institutionCode: string; // SaaS customer id i.e. ABC for ABC implementation
	leadSource: string;
	loanAgreementStatus: string;
	idVerificationStatus: string;
	subProductName: string | null;
	selectedImage: string;
	losStatus: string;
	underWritingStatus: string;
	membershipEligibilityCriteria: string;
	purposeDesc: string;
	documentsStatus: string;
	campaignType: string;
	promoCode: string;
	merchantName: string;
	partnerName?: string;
	partnerNumber?: string;
	referrerId?: string;
	lenderName: string;
	extendedAttributesVOList: ExtendedAttributesModel

	otpVerifyChannel = 'SMS';
	otpVerifyCode: string;

	/**/
	// deviceSessionId: string;
	deviceSessionId: any;

	paymentMethod: string;

	constructor() {
		this.applicants = [];
		//this.collateral = new CollateralModel();
		this.applicationType = 'INDIVIDUAL';
		this.productType = 'AUTO';
		this.appDate = new Date();
		this.channel = 'web';
		this.institutionCode = AppConfigService.env.institutionId;
		this.expireDate = new Date(Date.now() + (30 * 24 * 60 * 60 * 1000));
		this.consents = [];
		this.deviceSessionId = {
			generalUserProfile: {
				deviceType: getDeviceType(),
				userAgent: window.navigator.userAgent
			}
		}
	}
}

