import {AddressModel} from './address.model';
import {ResidenceModel} from './residence.model';
import {EmploymentModel} from './employment.model';
import {IncomeModel} from './income.model';
import { ConsentModel } from './consent.model';

export class ApplicantModel {
	applicationId: number;
	applicantId: number;
	applicantType: string; // Primary, Secondary, etc
	currentEmployment: string;
	customerIdentifier: string;
	firstName: string;
	lastName: string;
	middleName: string;
	mothersMaidenName: string;
	surname: string;
	dob: string;
	ssn: string;
	last4SSN: string;
	email: string;
	citizenship: string;
	citizenshipStatus: string;
	citizenInd: boolean; // Yes - US, No - Non US
	permanentResident: boolean; // Yes - PR, No - Non PR
	primaryPhone: string;
	homePhone: string;
	workPhone: string;
	dateOfBirth: Date;
	category: string; // 0 = Business | 1 = Individual
	relevancyScore: number;
	creditTier: string;
	housingCost: number;
	income: number;
	militaryActiveDutyIndicator: boolean; // 0 = Non - Military | 1 = Active | 2 = Retired
	maritalStatus: string;
	spouseName: string;
	consent: string;
	consentVOList: ConsentModel[];
	address: AddressModel;
	residence: ResidenceModel;
	employers: EmploymentModel[];
	drivingLicenseNumber: string;
	drivingLicenseState: string;
	additionalIncomes: IncomeModel[];
	additionalAddressDetails: AddressModel[];
	purposeDesc: string;
	relationShipToPrimaryApplicant: string;
	idType: string;
	idIssuer: string;
	idNumber: string;
	idIssueDate: Date;
	idExpDate: Date;
	member: boolean;
	membershipEligibilityCriteria: string;
	affiliatedSponsorOrganization: string;
	affiliatedSponsorOrganizationCode: string;
	familyMemberLastName: string;
	relationShipToFamilyMember: string;
	familyMemberFirstName: string;
	relationShip: string;

	// company model data
	option: string;
	title: string;
	companyLegalName: string;
	companyTaxIdentificationNumber: string;
	companyEntityType: string;
	companyWebSite: string;
	companyIndustry: string;
	businessDescription: string;
	controllingManagerType: string;
	businessLength: number;
	employeeCount: number;
	percentageOwnership: number;
	prospectEncryptedKey: string;
	stateOfBusinessFormation: string;

	referenceId: number;
	type: string;
	addedToCore: boolean;
	derosDate: any;

	constructor() {
		this.applicantType = 'P';
		this.address = new AddressModel();
		this.residence = new ResidenceModel();
		this.employers = [];
		this.category = 'INDIVIDUAL';
		this.relevancyScore = 0;
		this.citizenInd = null;
		this.militaryActiveDutyIndicator = false;
		this.additionalIncomes = [];
		this.firstName = '';
		this.lastName = '';
		this.middleName = null;
	}
}
