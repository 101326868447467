import {Component, Inject, OnInit} from "@angular/core";
import {KeyIdentifierModel} from "@creditsnap/data-models";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {APP_ENV_CONFIG, SharedKeyDataService} from '@creditsnap/app-config';
import {APP_CONFIG} from "@creditsnap/app-config";

@Component({
	selector: "creditsnap-purpose-code-modal",
	templateUrl: "./purpose-code-modal.component.html",
	styleUrls: ["./purpose-code-modal.component.scss"]
})
export class PurposeCodeModalComponent implements OnInit {
	keyIdentifier: KeyIdentifierModel;
	purposeCodeList = [];
	selectedPurposeCode: string;
	selectedLoanPurpose: string;
	barButtonOptions: any = {
		active: false,
		text: "Continue",
		buttonColor: "primary",
		barColor: "#C1D72E",
		raised: true,
		stroked: false,
		mode: "indeterminate",
		value: 0,
		disabled: false
	};
	otherPurposeCode: string;

	constructor(@Inject(APP_CONFIG) public appConfig: any,
				@Inject(MAT_DIALOG_DATA) public data: any,
				@Inject(APP_ENV_CONFIG) public environment: any,
				public dialogRef: MatDialogRef<PurposeCodeModalComponent>,
				private sharedKeyDataService: SharedKeyDataService,) {
		sharedKeyDataService.keyIdentifier$.subscribe((data) => {
            this.keyIdentifier = data;
        });
		this.dialogRef.disableClose = true;
		let loanPurpose = this.keyIdentifier.loanPurpose;
		if(data && data.loanPurpose) {
			loanPurpose = data.loanPurpose;
		}
		this.purposeCodeList = this.appConfig.loanPurpose[loanPurpose].purposeCodeList;
	}

	ngOnInit() {
		this.dialogRef.keydownEvents().subscribe(event => {
			if (event.key === "Escape") {
				this.dialogRef.close();
			}
		});
	}

	onSubmit() {
		//const isDebtConsolidation = this.selectedPurposeCode === "Debt Consolidation";
		let selectedPurposeCode = this.selectedPurposeCode;
		this.selectedLoanPurpose = this.keyIdentifier.loanPurpose;
		if (this.selectedPurposeCode.includes('^')) {
			const concatenatedValue = this.selectedPurposeCode.split('^');
			this.selectedLoanPurpose = concatenatedValue[0];
			selectedPurposeCode = concatenatedValue[1];

		}
		this.dialogRef.close({
			purposeCode: selectedPurposeCode?.toLowerCase() === 'other' ? this.otherPurposeCode : selectedPurposeCode,
			loanPurpose: this.selectedLoanPurpose
		});
	}
}
