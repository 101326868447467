<mat-dialog-content>
  <div class="otp-modal text-center">
    <div class="modal-details text-center">
      <h2> Please enter the One-Time Password to verify your account </h2>
      <h5 *ngIf="invalidOTPError" class="mat-error">Please enter a valid One Time Password</h5>
      <h6>A One Time Password is sent to {{phone | phoneMask}}. Enter the code below.</h6>
      <ngx-otp-input #otpInput [config]="otpInputConfig"
                     (otpChange)="handeOtpChange($event)"
                     [status]="otpInputStatus"
      ></ngx-otp-input>

      <div>
        <mat-bar-button [options]="barButtonOptions"
                        class="mb-md-0 btn btn-accept p-3 mt-4 text-xl"
                        matTooltip="Validate" name="validateOtp" id="validateOtp" (click)="validateOtp()">
        </mat-bar-button>
        <div class="mb-md-0 btn p-3 mt-4 text-xl">
          <a mat-stroked-button href="javascript:void(0)" id="cancle" (click)="changeNumber()">Cancel</a>
        </div>
      </div>

      <h6>
        <a href="javascript:void(0)" [style.pointer-events]="(resendOtpEnableCount !== 0) ? 'none' : ''" id="resendOtp"
           (click)="sendOTP()">
          Resend One-Time Password {{(resendOtpEnableCount > 0) ? '(' + resendOtpEnableCount + ')' : ''}}
        </a>
      </h6>
      <h6 *ngIf="!reEntry"><a href="javascript:void(0)" id="wrongNumberEntered" (click)="changeNumber()" id="wrongNumberEntered">Entered
        a Wrong Number?</a></h6>
    </div>
  </div>
</mat-dialog-content>
