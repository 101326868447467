import {Injectable} from '@angular/core';
import {
	ApplicationModel,
	CollateralModel,
	KeyIdentifierModel,
	LoanAmountVariationsModel,
	OfferModel,
	OfferOptionsVO,
	OfferVariationModel,
	TaxModel
} from '@creditsnap/data-models';
import {UtilsService} from './util.service';
import {calcDTILoanAmount, calcEMI} from './offer-with-ltv.util.service';
import {LabelType} from "ng5-slider";
import {CurrencyPipe} from "@angular/common";

/**
 * Provide custom Offer util service for offer related calculations, filter params etc.
 */
@Injectable({
	providedIn: 'root'
})
export class OfferUtilService {

	constructor(public utilService: UtilsService, private currencyPipe: CurrencyPipe) {
		this.utilService = new UtilsService();
	}

	generateOfferVariations(offers: OfferModel[] = []): any {
		// const offerVariations: OfferVariationTypeModel = new OfferVariationTypeModel();
		const offerVariations: OfferVariationModel[] = [];
		let offerVariation: OfferVariationModel;
		// @ts-ignore
		offers.forEach(function (obj) {
			if (obj.variation === null || obj.variation === undefined ||
				obj.maxAvailableCredit === undefined || obj.maxAvailableCredit === null ||
				obj.maxAvailableCredit === 0) {
				return true;
			}
			obj.variation.forEach(function (variation) {
				offerVariation = new OfferVariationModel();
				offerVariation = copyObject(offerVariation, obj, variation);
				offerVariations.push(offerVariation);
				offerVariation = null;
			});
		});
		return offerVariations;
	}

	// @ts-ignore
	termOrAmountChanged(items: OfferVariationModel[] = [], customOptions: OfferOptionsVO): OfferVariationModel {
		const offers = items.filter((el) => {
			return (el.defaultTerm === customOptions.selectedTerm
				&& el.maxAvailableCredit >= customOptions.customizedLoanAmount &&
				customOptions.customizedLoanAmount >= this.findMinLoanAmount(el));
		});
		if (offers && offers.length > 0) {
			const offer = offers.reduce((min, p) => p.apr < min.apr ? p : min);
			offer.customizedLoanAmount = customOptions.customizedLoanAmount;
			return calcEMIWithoutLTV(offer, customOptions.optedAch);
		}
	}

	recommendedOfferOptedAch(item: OfferVariationModel = new OfferVariationModel(), optedAch: boolean): OfferVariationModel {
		return calcEMIWithoutLTV(item, optedAch);
	}

	findAvailableOfferOptions(items: OfferVariationModel[] = null, loanAmountVariations: LoanAmountVariationsModel): OfferOptionsVO {
		const offerOptionsVO: OfferOptionsVO = new OfferOptionsVO();
		const defaultMin: number = loanAmountVariations.loanPurposeConfig.minLoanAmount;
		let collateral: CollateralModel;
		let eligibleLoanAmount: number = 9999999; // transaction value
		let selectedLenderId: number = 0;
		let selectedTerm: number = loanAmountVariations.selectedTerm || 0;
		let regeneratedOffer: boolean = false;
		let isOfferVariationSelected: boolean = false;
		const fixedRecommendations: any = {
			lowerPayment: null,
			lowerInterest: null,
			lowestAPR: null,
		};
		const variableRecommendations: any = {
			lowerPayment: null,
			lowerInterest: null,
			lowestAPR: null,
		};
		// Step1: Check whether offer is selected by customer or not

		//console.log(' eligibleLoanAmount, loanAmountVariations =>', eligibleLoanAmount, loanAmountVariations);
		if (loanAmountVariations.hasCustomerOptedOffer && loanAmountVariations.previouslySelectedOffer) {
			eligibleLoanAmount = loanAmountVariations.customizedLoanAmount;
			selectedLenderId = loanAmountVariations.previouslySelectedOffer.lender.lenderId;
			loanAmountVariations.previouslySelectedOffer.variation.forEach(function (obj) {
				if (obj.isVariationSelected) {
					regeneratedOffer = true;
					selectedTerm = obj.defaultTerm;
				}
			});
			//console.log(' eligibleLoanAmount, loanAmountVariations =>', eligibleLoanAmount, loanAmountVariations);
		} else if (loanAmountVariations.customizedLoanAmount && loanAmountVariations.customizedLoanAmount > 0) {
			// Step 2: For line of credit and cards customise loan amount will be 0 OR null only collatral base loan should come here
			//         if loan amount is set in the API, then this block should come into play
			eligibleLoanAmount = loanAmountVariations.customizedLoanAmount;
		} else if (!loanAmountVariations.loanPurposeConfig.offer.slider && loanAmountVariations.loanPurposeConfig.termBasedLoan) {
			// Step 3: it is refi and pay-off amount available then set the eligible loan amount. If slider is false then it implies
			// payoff amount is the loan amount and that means it is a refi transaction
			eligibleLoanAmount = loanAmountVariations.payOffAmount;
			collateral = loanAmountVariations.collateral;
		} else {
			eligibleLoanAmount = 0;
		}
		//console.log(' eligibleLoanAmount 2 =>', eligibleLoanAmount);
		eligibleLoanAmount = eligibleLoanAmount > defaultMin ? eligibleLoanAmount : defaultMin;

		items.forEach((item: OfferVariationModel) => {
			// tslint:disable-next-line:max-line-length
			// TODO - Long Term: include tax into eligible loan amount only for comparison except maxLTVamount without updating original amount.
			/* let eligibleAmountVariation = Math.min(eligibleLoanAmount, item.maxLoanAmount, item.maxAvailableCredit);

			  if (loanAmountVariations.isVehicleValuationCompleted && item.maxLTVLoanAmount > 0) {
				  eligibleAmountVariation = Math.min(item.maxLTVLoanAmount, eligibleAmountVariation);
			  }*/
			/*
			   const eligibleLoanAmountWithTax = eligibleLoanAmount + loanAmountVariations.totalTax;

			   let eligibleAmountVariation = Math.min(eligibleLoanAmountWithTax, item.maxLoanAmount, item.maxAvailableCredit);

			   if (loanAmountVariations.isVehicleValuationCompleted && item.maxLTVLoanAmount > 0) {
				   const eligibleAmountVariationWithLTV = Math.min(item.maxLTVLoanAmount, eligibleLoanAmount);

				   eligibleAmountVariation = Math.min(eligibleAmountVariationWithLTV, eligibleAmountVariation);

			   } */
			// if (loanAmountVariations.selectedTerm != 0 && item.defaultTerm != loanAmountVariations.selectedTerm) {
			//     return;
			// }
			let obj = item;

			if (!item.maxLoanAmountCalculationDone) {
				if (item.maxDTI < 1) {
					item.maxDtiEmi = (item.maxDTI - item.preDTI) * item.salary;
					// Calc maxDTILoanAmount
					item = calcDTILoanAmount(item);

					// eligibleAmountVariation = Math.min(item.maxDTILoanAmount, eligibleAmountVariation);
				}

				item.maxAvailableAmount = this.findMaxLoanAmountForVariation(item, loanAmountVariations);
				// Find the min loan amount
				item.minAvailableAmount = this.findMinLoanAmount(item, loanAmountVariations);
				item.maxLoanAmountCalculationDone = true;
			}
			offerOptionsVO.maxLoanAmountForAllVariations = Math.max(offerOptionsVO.maxLoanAmountForAllVariations, item.maxAvailableAmount);
			offerOptionsVO.minLoanAmountForAllVariations = Math.min(offerOptionsVO.minLoanAmountForAllVariations, item.minAvailableAmount);

			// set customised loan amount value
			if (eligibleLoanAmount > item.maxAvailableAmount) {
				item.customizedLoanAmount = item.maxAvailableAmount;
			} else {
				item.customizedLoanAmount = eligibleLoanAmount;
			}

			// Assign Slider options here so don't need to calculate again at run time on manage offer screen
			item.sliderOptions = {
				floor: loanAmountVariations.loanPurposeConfig.id === 'CLI' ? (collateral?.loanAmount || 500) : item.minAvailableAmount,
				ceil: item.maxAvailableAmount,
				step: 100,
				translate: (value: number, label: LabelType): string => {
					return this.currencyPipe.transform(Math.round(Number(value) || 0), 'USD', 'symbol', '1.0-0').toString();
				}
			}

			item.fullyLoadedLoanAmount = item.customizedLoanAmount + item.totalTaxesInFrontendLTV;

			if (item.lender.addOnProductAmountIntoFrontedLTV) {
				item.productCapacity = item.maxLTVLoanAmount - item.fullyLoadedLoanAmount;
			} else if (item.backendLtvMax && item.backendLtvMax < 999) {
				item.productCapacity = item.maxBackendLTVLoanAmount - item.fullyLoadedLoanAmount;
			}

			if (item.backendProductMax) {
				item.productCapacity = item.productCapacity > item.backendProductMax ? item.backendProductMax : item.productCapacity;
			}

			// Bypass Filter Logic if it is not a term base loan EX. LOC, CC
			if (loanAmountVariations.loanPurposeConfig.termBasedLoan) {
				// const eligibleAmountVariation = Math.min(eligibleLoanAmount, maxLoanAmount);
				// console.log('item.customizedLoanAmount, minAmount, maxAvailableAmount => ',
				//     item.customizedLoanAmount, minAmount, maxAvailableAmount);

				// include loan amount tax to calculate emi
				item.totalTax = loanAmountVariations.collateral && loanAmountVariations.collateral.loanAmountTax || 0;

				// By default ACH discounts are included if lender offers.
				obj = calcEMI(item, true, false, loanAmountVariations.loanPurposeConfig.productAtOffer);
				if (item.maxDTI < 1) {
					item.postDTI = item.preDTI + (obj.emi / item.salary);
				}

				/*  console.log('minAmount: ', item.minAvailableAmount);
				  console.log('item.maxAvailableAmount: ', item.maxAvailableAmount);
				  console.log('maxAvailableAmountForAllVariations: ', offerOptionsVO.maxLoanAmountForAllVariations);
				  console.log('eligibleLoanAmount  ', eligibleLoanAmount);*/
				// Do not add the variation to cards if the customized loan amount is less than min loan amount.
				if (eligibleLoanAmount < item.minAvailableAmount || eligibleLoanAmount > item.maxAvailableAmount
					|| (item.postDTI && item.postDTI < item.minDTI)) {
					//console.log('Lender does not offer less than min loan a mount, so skipping =>',eligibleLoanAmount, item.minAvailableAmount, item.maxAvailableAmount);
					return;
				}

				if (loanAmountVariations.loanPurposeConfig.offer.saving && collateral) {
					item.interestSaving = item.emi < collateral.monthlyPayment ? collateral.monthlyPayment - item.emi : null;
					item.aprSaving = item.withAllAPR < collateral.currentLoanAPR ? collateral.currentLoanAPR - item.withAllAPR : null;
				}
			}

			if (obj.rateType === 'variable') {
				// Find out the lowest payment options
				if (!variableRecommendations.lowerPayment) {
					variableRecommendations.lowerPayment = obj;
				} else if (obj.emi < variableRecommendations.lowerPayment.emi) {
					variableRecommendations.lowerPayment = obj;
				}
				// Find out the lowest interest option
				if (!variableRecommendations.lowerInterest) {
					variableRecommendations.lowerInterest = obj;
				} else if (obj.totalInterest < variableRecommendations.lowerInterest.totalInterest) {
					variableRecommendations.lowerInterest = obj;
				}
				// Find out the lowest APR option
				if (!variableRecommendations.lowestAPR) {
					variableRecommendations.lowestAPR = obj;
				} else if (obj.apr < variableRecommendations.lowestAPR.apr) {
					variableRecommendations.lowestAPR = obj;
				}
			} else {
				if (!fixedRecommendations.lowerPayment) {
					fixedRecommendations.lowerPayment = obj;
				} else if (obj.emi < fixedRecommendations.lowerPayment.emi) {
					fixedRecommendations.lowerPayment = obj;
				}
				// Find out the lowest interest option
				if (!fixedRecommendations.lowerInterest) {
					fixedRecommendations.lowerInterest = obj;
				} else if (obj.totalInterest < fixedRecommendations.lowerInterest.totalInterest) {
					fixedRecommendations.lowerInterest = obj;
				}
				// Find out the lowest APR option
				if (!fixedRecommendations.lowestAPR) {
					fixedRecommendations.lowestAPR = obj;
				} else if (obj.apr < fixedRecommendations.lowestAPR.apr) {
					fixedRecommendations.lowestAPR = obj;
				}

			}

			obj.isVariationSelected = (obj.isVariationSelected === null || obj.isVariationSelected === undefined)
				? false : obj.isVariationSelected;
			if (regeneratedOffer && obj.isOfferSelected && obj.isVariationSelected) {
				//console.log('finding the selected offer =>', obj.lender.lenderId, selectedLenderId,obj.offerVariationId, obj.isOfferSelected, obj.isVariationSelected);
				isOfferVariationSelected = true;
			}
			/* if (obj.isVariationSelected) {
				 console.log('selected variation finding the selected offer =>', obj.lender.lenderId, selectedLenderId,
					 obj.isOfferSelected, obj.isVariationSelected, regeneratedOffer, obj.offerId, obj.offerVariationId,
					 obj.defaultTerm, selectedTerm);
				 //   obj.isVariationSelected = (obj.defaultTerm === selectedTerm) ? true : obj.isVariationSelected;
			 }*/
			obj.isLenderOfferOnlineRates = true;
			//   offerModel.relevancyScore = variation.relevancyScore === undefined || variation.relevancyScore === null
			//   ? 0 : variation.relevancyScore;
			obj.payOffAmount = loanAmountVariations.payOffAmount;
			obj.hasDownPaymentRequired = false;

			// tslint:disable-next-line:max-line-length
			if (loanAmountVariations.loanPurposeConfig.offer.downPayment && obj.payOffAmount > obj.customizedLoanAmount && obj.payOffAmount > 0) {
				// tslint:disable-next-line:max-line-length
				//console.log('obj.downPayment, obj.payOffAmount, obj.customizedLoanAmount ', obj.downPayment, obj.payOffAmount, obj.customizedLoanAmount);
				obj.downPayment = Math.floor((obj.payOffAmount - obj.customizedLoanAmount) / 10) * 10;
				obj.hasDownPaymentRequired = obj.downPayment > 0;
			} else {
				obj.downPayment = 0;
				obj.hasDownPaymentRequired = false;
			}
			// Check whether previously selected offer lender term available or not
			obj.selectedPriorLTV = false;
			if (loanAmountVariations.previouslySelectedOffer) {
				obj.selectedPriorLTV = (loanAmountVariations.previouslySelectedOffer.lender.lenderId === obj.lender.lenderId &&
					obj.defaultTerm === selectedTerm);
			}
			offerOptionsVO.variations.push(obj);
		});

		//console.log('offerOptionsVO variations', offerOptionsVO.variations);
		if (offerOptionsVO.variations.length <= 0) {
			offerOptionsVO.variations = items.filter((item) => {
				// tslint:disable-next-line:max-line-length
				if (offerOptionsVO.maxLoanAmountForAllVariations === item.maxAvailableAmount && item.maxAvailableAmount >= this.findMinLoanAmount(item, loanAmountVariations)) {
					item.customizedLoanAmount = offerOptionsVO.maxLoanAmountForAllVariations;
					// By default ACH discounts are included if lender offers.
					const obj = calcEMI(item, true);

					if (!loanAmountVariations.loanPurposeConfig.offer.slider && collateral) {
						item.interestSaving = item.emi < collateral.monthlyPayment ? collateral.monthlyPayment - item.emi : null;
						item.aprSaving = item.withAllAPR < collateral.currentLoanAPR ? collateral.currentLoanAPR - item.withAllAPR : null;
					}
					// Find out the lowest payment options
					// noinspection DuplicatedCode
					if (obj.rateType === 'variable') {
						// Find out the lowest payment options
						if (!variableRecommendations.lowerPayment) {
							variableRecommendations.lowerPayment = obj;
						} else if (obj.emi < variableRecommendations.lowerPayment.emi) {
							variableRecommendations.lowerPayment = obj;
						}
						// Find out the lowest interest option
						if (!variableRecommendations.lowerInterest) {
							variableRecommendations.lowerInterest = obj;
						} else if (obj.totalInterest < variableRecommendations.lowerInterest.totalInterest) {
							variableRecommendations.lowerInterest = obj;
						}
						// Find out the lowest APR option
						if (!variableRecommendations.lowestAPR) {
							variableRecommendations.lowestAPR = obj;
						} else if (obj.apr < variableRecommendations.lowestAPR.apr) {
							variableRecommendations.lowestAPR = obj;
						}
					} else {
						if (!fixedRecommendations.lowerPayment) {
							fixedRecommendations.lowerPayment = obj;
						} else if (obj.emi < fixedRecommendations.lowerPayment.emi) {
							fixedRecommendations.lowerPayment = obj;
						}
						// Find out the lowest interest option
						if (!fixedRecommendations.lowerInterest) {
							fixedRecommendations.lowerInterest = obj;
						} else if (obj.totalInterest < fixedRecommendations.lowerInterest.totalInterest) {
							fixedRecommendations.lowerInterest = obj;
						}
						// Find out the lowest APR option
						if (!fixedRecommendations.lowestAPR) {
							fixedRecommendations.lowestAPR = obj;
						} else if (obj.apr < fixedRecommendations.lowestAPR.apr) {
							fixedRecommendations.lowestAPR = obj;
						}

					}

					obj.isVariationSelected = (obj.isVariationSelected === null || obj.isVariationSelected === undefined)
						? false : obj.isVariationSelected;
					if (regeneratedOffer && obj.isOfferSelected && obj.isVariationSelected) {
						//console.log('finding the selected offer =>', obj.lender.lenderId, selectedLenderId,obj.offerVariationId, obj.isOfferSelected, obj.isVariationSelected);
						isOfferVariationSelected = true;
					}
					/* if (obj.isVariationSelected) {
						 console.log('selected variation finding the selected offer =>', obj.lender.lenderId, selectedLenderId,
							 obj.isOfferSelected, obj.isVariationSelected, regeneratedOffer, obj.offerId, obj.offerVariationId,
							 obj.defaultTerm, selectedTerm);
						 //   obj.isVariationSelected = (obj.defaultTerm === selectedTerm) ? true : obj.isVariationSelected;
					 }*/
					obj.isLenderOfferOnlineRates = true;
					//   offerModel.relevancyScore = variation.relevancyScore === undefined || variation.relevancyScore === null
					//   ? 0 : variation.relevancyScore;
					obj.payOffAmount = loanAmountVariations.payOffAmount;
					obj.hasDownPaymentRequired = false;

					// tslint:disable-next-line:max-line-length
					if (loanAmountVariations.loanPurposeConfig.offer.downPayment && obj.payOffAmount > obj.customizedLoanAmount && obj.payOffAmount > 0) {
						obj.downPayment = obj.payOffAmount - obj.customizedLoanAmount;
						obj.hasDownPaymentRequired = obj.downPayment > 0;
					} else {
						obj.downPayment = 0;
						obj.hasDownPaymentRequired = false;
					}
					// Check whether previously selected offer lender term available or not
					obj.selectedPriorLTV = false;
					if (loanAmountVariations.previouslySelectedOffer) {
						obj.selectedPriorLTV = (loanAmountVariations.previouslySelectedOffer.lender.lenderId === obj.lender.lenderId &&
							obj.defaultTerm === selectedTerm);
					}
					return true;
				}
				return false;
			});
		}

		//console.log('offerOptionsVO variations after', offerOptionsVO.variations);

		offerOptionsVO.terms.fixed = Array.from(new Set(offerOptionsVO.variations.filter(obj => obj.rateType.toLowerCase() === 'fixed').map(
			obj => obj.defaultTerm))).sort((a, b) => a < b ? -1 : 1);
		offerOptionsVO.terms.variable = Array.from(new Set(offerOptionsVO.variations.filter(obj => obj.rateType.toLowerCase() === 'variable').map(
			obj => obj.defaultTerm))).sort((a, b) => a < b ? -1 : 1);

		offerOptionsVO.recommendations = {
			fixed: [],
			variable: []
		};

		if (fixedRecommendations.lowerPayment) {
			fixedRecommendations.lowerPayment.offerCategory = 'Lowest Payment';
			fixedRecommendations.lowerPayment.offerCategoryCode = 'P';
			offerOptionsVO.recommendations.fixed.push(Object.assign({}, fixedRecommendations.lowerPayment));
		}
		if (fixedRecommendations.lowestAPR && offerOptionsVO.terms.fixed.length > 1) {
			fixedRecommendations.lowestAPR.offerCategory = 'Lowest APR';
			fixedRecommendations.lowestAPR.offerCategoryCode = 'A';
			offerOptionsVO.recommendations.fixed.push(Object.assign({}, fixedRecommendations.lowestAPR));
		}

		if (variableRecommendations.lowerPayment) {
			variableRecommendations.lowerPayment.offerCategory = 'Lowest Payment';
			variableRecommendations.lowerPayment.offerCategoryCode = 'P';
			offerOptionsVO.recommendations.variable.push(Object.assign({}, variableRecommendations.lowerPayment));
		}
		if (variableRecommendations.lowestAPR && offerOptionsVO.terms.variable.length > 1) {
			variableRecommendations.lowestAPR.offerCategory = 'Lowest APR';
			variableRecommendations.lowestAPR.offerCategoryCode = 'A';
			offerOptionsVO.recommendations.variable.push(Object.assign({}, variableRecommendations.lowestAPR));
		}


		//console.log('offerOptionsVO 123', offerOptionsVO);

		const actualMax = offerOptionsVO.maxLoanAmountForAllVariations;
		const actualMin = offerOptionsVO.minLoanAmountForAllVariations;
		offerOptionsVO.loanAmtOptions = {actualMin, actualMax, min: actualMin, max: actualMax, step: 100};
		// offerOptionsVO.variations.push(offerOptionsVO.selectedVariation);
		if (eligibleLoanAmount > offerOptionsVO.maxLoanAmountForAllVariations) {
			loanAmountVariations.customizedLoanAmount = offerOptionsVO.maxLoanAmountForAllVariations;
		} else {
			loanAmountVariations.customizedLoanAmount = eligibleLoanAmount;
		}
		offerOptionsVO.loanAmountVariations = loanAmountVariations;

		return offerOptionsVO;
	}

	findMinLoanAmount(obj: any, loanAmountVariations: any = {}) {
		if (!obj) {
			return 0;
		}
		// min is always min of lender(offer) min and offer variation min
		// There is no min LTV, Min LTV is in rate lookup tables only to facilitate rate lookup
		let minLoanAmount;

		if (loanAmountVariations.loanPurposeConfig && loanAmountVariations.loanPurposeConfig.termBasedLoan) {
			minLoanAmount = Math.max(obj.minAvailableCredit, obj.minLoanAmount);
		} else {
			minLoanAmount = obj.minAvailableCredit;
		}

		return minLoanAmount;
	}

	findMaxLoanAmountForVariation(item: OfferVariationModel, loanAmountVariations: any) {
		let maxLoanAmount;

		if (loanAmountVariations.loanPurposeConfig && loanAmountVariations.loanPurposeConfig.termBasedLoan) {
			maxLoanAmount = Math.min(item.maxLoanAmount, item.maxAvailableCredit);

			if (loanAmountVariations.isVehicleValuationCompleted && item.maxLTVLoanAmount > 0) {
				maxLoanAmount = Math.min(item.maxLTVLoanAmount, maxLoanAmount);
			}

			if (item.maxDTI < 1 && item.maxDTILoanAmount) {
				maxLoanAmount = Math.min(item.maxDTILoanAmount, maxLoanAmount);
			}
		} else {
			maxLoanAmount = item.maxAvailableCredit;
		}
		//console.log('inside findMaxLoanAmountForVariation', maxLoanAmount);
		return maxLoanAmount;

	}

	calculateCollateralTax(collateral: CollateralModel, loanAmountVariations: LoanAmountVariationsModel) {
		let totalTax = 0;
		let loanTax = 0;
		if (!collateral || !collateral.taxes || collateral.taxes.length <= 0) {
			return totalTax;
		}
		if (!collateral.retailValueWithAccessories || !collateral.tradeInWithAccessories) {
			collateral = this.calculateCollateralValueWithAccessories(collateral);
		}
		// tslint:disable-next-line:max-line-length
		const customizedLoanAmount = loanAmountVariations.loanPurposeConfig.offer.slider ? loanAmountVariations.customizedLoanAmount : loanAmountVariations.payOffAmount;
		collateral.taxes.map((tax: TaxModel) => {
			const calculatedTax = tax.taxCalculated + (tax.loanAmountVariableTaxRate * customizedLoanAmount) +
				(tax.highestOfRetailAndLoanAmountVariableTaxRate * Math.max(collateral.retailValueWithAccessories, customizedLoanAmount)) +
				tax.highestOfTradeInAndLoanAmountVariableTaxRate * Math.max(collateral.tradeInWithAccessories, customizedLoanAmount);
			totalTax += calculatedTax;
			if (tax.includeInLoanAmount) {
				loanTax += calculatedTax;
			}
		});
		collateral.totalTax = totalTax;
		collateral.loanAmountTax = loanTax;
		return totalTax;
	}

	calculateCollateralValueWithAccessories(collateral: CollateralModel, sliderDefaultAmountType: any = null) {
		let tradeIn: number = 0;
		let retailValue: number = 0;
		if (collateral.properties) {
			collateral.properties.forEach(elem => {
				if (elem.added) {
					retailValue += elem.retailValue;
					tradeIn += elem.tradeIn;
				}
				//  console.log('1st accessories : ', elem.accDesc, elem.added);
				return elem;
			});
		}

		let collateralBaseValue: number;
		switch (sliderDefaultAmountType) {
			case 'tradeIn':
				collateralBaseValue = collateral.tradeIn;
				break;
			case 'tradeInPlusVin':
				collateralBaseValue = collateral.tradeInPlusVin;
				break;
			case 'tradeInPlusVinMileage':
				collateralBaseValue = collateral.tradeInPlusVinMileage;
				break;
			case 'retailValue':
				collateralBaseValue = collateral.retailValue;
				break;
			case 'retailPlusVin':
				collateralBaseValue = collateral.retailPlusVin;
				break;
			case 'retailPlusVinMileage':
				collateralBaseValue = collateral.retailPlusVinMileage;
				break;
			default:
				collateralBaseValue = collateral.tradeIn;
				break;
		}

		collateral.retailValueWithAccessories = collateralBaseValue + collateral.mileageAdj + retailValue;
		collateral.tradeInWithAccessories = collateralBaseValue + collateral.mileageAdj + tradeIn;
		return collateral;
	}

	getLoanAmountFromCollateral(identifier: KeyIdentifierModel, loanAmountVariations: LoanAmountVariationsModel, application: ApplicationModel) {
		let collateral = loanAmountVariations.collateral;
		const sliderDefaultAmountType = identifier.loanPurposeConfig.sliderDefaultAmountType;
		let loanAmount = 0;
		if(sliderDefaultAmountType !== null){
			if (sliderDefaultAmountType === 'tradeIn') {
				if (collateral && collateral.collateralId) {
					collateral = this.calculateCollateralValueWithAccessories(collateral, sliderDefaultAmountType);
					loanAmount = collateral.tradeInWithAccessories;
				}
			} else if (sliderDefaultAmountType === 'purchasePrice') {
				loanAmount = collateral.purchasePrice;
			} else if (sliderDefaultAmountType === 'retailValue') {
				if (collateral && collateral.collateralId) {
					collateral = this.calculateCollateralValueWithAccessories(collateral, sliderDefaultAmountType);
					loanAmount = collateral.retailValueWithAccessories;
				}
			} else if (sliderDefaultAmountType === 'loanAmount') {
				loanAmount = (collateral && collateral.loanAmount) || application.loanAmount;
			}
		}
		return loanAmount || loanAmountVariations.loanPurposeConfig.defaultLoanAmount || 999999999;
	}

	calcEmiWithTax(offerOptionsVO: OfferOptionsVO) {

		const loanAmountVariations: LoanAmountVariationsModel = offerOptionsVO.loanAmountVariations;

		offerOptionsVO.variations.map(variation => {
			// include tax to calculate emi
			variation.totalTax = loanAmountVariations.collateral && loanAmountVariations.collateral.loanAmountTax || 0;
			calcEMI(variation, true, true);
		});

		offerOptionsVO.recommendations.fixed.map((variation: any) => {
			// include tax to calculate emi
			variation.totalTax = loanAmountVariations.collateral && loanAmountVariations.collateral.loanAmountTax || 0;
			calcEMI(variation, true, true);
		});

		if (offerOptionsVO.recommendations.variable.length > 0) {
			offerOptionsVO.recommendations.variable.map((variation: any) => {
				// include tax to calculate emi
				variation.totalTax = loanAmountVariations.collateral && loanAmountVariations.collateral.loanAmountTax || 0;
				calcEMI(variation, true, true);
			});
		}
	}
}

/**
 * @description Chart monthly loan balance, interest and equity in an HTML <canvas> element.
 *      If called with no arguments then just erase any previously drawn chart.
 * @return OfferVariationModel
 * @param item
 * @param achOpted
 */
export function calcEMIWithoutLTV(item: OfferVariationModel = new OfferVariationModel(), achOpted: boolean): OfferVariationModel {
	// Convert interest from a percentage to a decimal, and convert from
	// an annual rate to a monthly rate. Convert payment period in years
	// to the number of monthly payments.
	// MonthlyPayment*(1-(1+((APR/12)/100)^-Term)/((APR/12)/100)) -- when we do not know the total loan amount
	// Default ACH applied, the ach option is auto selected on page load.

	// const _loanTerms = Object.assign({}, item);
	if (item.isLenderOfferAch && achOpted) {
		item.withAllAPR = item.apr - item.achDiscountRate;
	} else {
		item.withAllAPR = item.apr;
	}
	/* if (item.customizedLoanAmount === undefined || item.customizedLoanAmount === null
		 || item.customizedLoanAmount === 0) {
		 item.customizedLoanAmount = item.maxAvailableCredit;
	 }*/
	const interest = item.withAllAPR / 100 / 12;

	// compute the monthly payment figure
	const x = Math.pow(1 + interest, item.defaultTerm); // Math.pow computes powers
	const monthly = (item.customizedLoanAmount * x * interest) / (x - 1);

	// If the result is a not finite number, then something wrong with input provided
	if (isFinite(monthly)) {
		// Rounding to 2 decimal places
		item.emi = monthly;
		item.totalAmount = (monthly * item.defaultTerm);
		item.totalInterest = ((monthly * item.defaultTerm) - item.customizedLoanAmount);
		item.isCalcSuccess = true;
	} else {
		// Result was Not-a-Number or infinite, which means the input was
		// incomplete or invalid. Clear any previously displayed output.
		item.isCalcSuccess = false;
	}
	return item;
}

/*
export function monthsBetweenDatesV1(_loanTerms: any = {}): number {
    const firstDate: Date = new Date(2018, 8, 8);
    const secondDate: Date = new Date(2018, 10, 28);
    const fm: number = firstDate.getMonth();
    const fy: number = firstDate.getFullYear();
    const sm: number = secondDate.getMonth();
    const sy: number = secondDate.getFullYear();
    let months = Math.abs(((fy - sy) * 12) + fm - sm);
    const firstBefore: boolean = firstDate > secondDate;
    firstDate.setFullYear(sy);
    firstDate.setMonth(sm);
    firstBefore ? firstDate < secondDate ? months-- : '' : secondDate < firstDate ? months-- : '';
    return months;
}
*/


export function copyObject(offerVariation: OfferVariationModel, obj: OfferModel, variation: OfferVariationModel): OfferVariationModel {
	const utilService = new UtilsService();
	return utilService.copyObject(offerVariation, obj, variation);
}

