<h3 class="ssn-header-title mb-0" mat-dialog-title>Based on the information provided, our system was unable to identify you.
	Please enter your Social Security Number (SSN)
	<p>({{applicant?.firstName + ' ' + applicant?.lastName}})</p>
</h3>
<form (ngSubmit)="f.form.valid" #f="ngForm" class="m-form m-form--group-seperator-dashed ssn-form-details" novalidate>
	<div mat-dialog-content>
		<div class="m-form__group">
			<div class="mat-form-border">
				<div class="mat-form-space">
					<mat-form-field class="mat-form-field-fluid w-100">
						<mat-label>Enter SSN number</mat-label>
						<input matInput name="ssn" ssn-mask
							   autocomplete="off" [(ngModel)]="applicant.ssn" #ssn="ngModel"
							   required>
						<mat-hint>We are unable to pull your soft inquiry credit report with the information provided and will need your SSN to continue. This will still be a soft inquiry and will not impact your credit.</mat-hint>
						<mat-error *ngIf="ssn.hasError('required')">SSN is <strong>required.</strong></mat-error>
						<mat-error *ngIf="ssn.hasError('min-max')">Enter valid SSN number.</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions class="p-4 pt-0" align="center">
		<div class="align-items-center d-flex flex-column">
			<div class="m-cs-subheader" *ngIf="errorMessage">
				<h6 class="font-danger">{{errorMessage}}</h6>
			</div>
			<mat-bar-button [options]="barButtonOptions" (tsSubmitIfValid)="onSubmit()" name="continueSSN" id="continueSSN"
							matTooltip="Providing this information will not impact your credit.">
			</mat-bar-button>
		</div>
	</div>
</form>
