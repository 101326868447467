export const commonConfig: any = {
	logoName: "https://s3.amazonaws.com/creditsnapimages/ABC/logo.png",
	phoneNumber: "111-111-1111",
	productSelection: false, // true for first page to show product selection with images
	multiSteps: false,
	stepsLocation: "", //top or "blank for no progress bar"
	imageFlow: false,
	imageForCollateral: false,
	existingMemberPrompt: false,
	branding: {},
	askOTP: true,
	otpVerifyChannel: 'SMS', // SMS or SMS_EMAIL
	stepsCount: 3,
	showRatingsWidgets: false,
	defaultLoanAmount: 500,
	isCoBorrowerRequired: true,
	isCitizenshipRequired: false,
	existingMemberFields: {
		lastName: true,
		dateOfBirth: true,
		zipCode: false,
		last4SSN: true,
		visible: true,
		firstName: false,
		email: false,
		memberNumber: false,
		existingMemberText: ""
	},
	isPermanentResidentRequired: false,
	defaultRateType: "fixed",
	preEligibilityCheck: false,
	relationshipToApplicant: true,
	employmentTypeList: [
		{ name: "Employed", value: "F" },
		{ name: "Unemployed", value: "N" },
		{ name: "Self Employed", value: "S" },
		{ name: "Retired", value: "R" },
		{ name: "Student", value: "ST" }
	],
	vehicleUndecided: true,
	hardInquiryConsent: "modal", //modal
	consent1_individual: `By providing information about yourself in this pre-qualification and selecting the box to the left,
    you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
         obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
         information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
          not a final application for credit.</li>
        </ul>`,
	consent1_individual_teller: `By providing information about yourself in this pre-qualification and selecting the box to the left,
	you consent, acknowledge, and agree to the following:
		<ul>
		<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
		<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
		<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
			obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
			information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
			not a final application for credit.</li>
		</ul>`,
	consent1_joint: `By providing information about yourself in this pre-qualification and selecting the box to the left,
            you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        <li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
        to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
        such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
        final application for credit.</li>
        </ul>`,
	consent1_skip_offer: `By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        </ul>`,
	consent2: "You consent to receive email, SMS or other marketing communications from ABC." +
		" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
		"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
		" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
		"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
		"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
	consent3_joint: "By selecting the box to the left, you agree that completing and submitting information in this pre-qualification on behalf " +
		"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
	consent3_joint_skip_offer: "By selecting the box to the left, you agree that completing and submitting information on behalf " +
		"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
	hardInquiryConsentText: "You authorize ABC to obtain your credit report from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_teller: "You authorize ABC to obtain your credit report from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	hardInquiryConsentText_joint: "You authorize ABC to obtain your credit report and Joint Applicant's credit report   from a credit reporting company. You acknowledge that this " +
		"is an application for credit and authorize ABC to obtain such information solely to evaluate your credit application. You are " +
		"also consenting to allow ABC to contact you and offer you savings and benefits on other products.",
	consentsConfig: {
		web: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
    		you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
				 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
				 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
				  not a final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_individual: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
				readMoreBtn: true
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
        <ul>
        <li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
        <li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
        </ul>`,
				required: true,
				consents: ['eConsent'],
				readMoreBtn: true
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry'],
				readMoreBtn: false
			},

			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
            		you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
				to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
				such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
				final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_joint: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
				readMoreBtn: true
			},
			//skipoffer joint
			consent3_joint: {
				text:'Skip offer joint',
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			consent1_prescreen: {
				text:'',
				required: true,
				consents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
				readMoreBtn: false
			},
			consent2_prescreen: {
				text:'',
				required: true,
				consents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
				readMoreBtn: false
			},

		},
		branch: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
    			you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
				 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
				 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
				  not a final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_individual: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
				readMoreBtn: true
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
					<ul>
					<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
					<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
					</ul>`,
				required: true,
				consents: ['eConsent'],
				readMoreBtn: true
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry'],
				readMoreBtn: false
			},

			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
            		you consent, acknowledge, and agree to the following:
					<ul>
					<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
					<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
					<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
					to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
					such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
					final application for credit.</li>
					</ul>`,
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_joint: {
				text:"You consent to receive email, SMS or other marketing communications from ABC." +
					" You further expressly agree that ABC may contact you at the calling numbers provided, including your cellular number, via live, " +
					"auto dialer, prerecorded telephone call, text, chat or email message. You further understand that your telephone company may impose" +
					" charges on you for these contacts and you are not required to provide this consent to be effective even if your calling numbers or " +
					"email address are included on a do-not-call list or registry. You understand that your consent to receive calls is not required in " +
					"order to purchase any goods or services. If you do not want to receive any marketing communications just un-check the box",
				required: false,
				consents: ['Marketing'],
				readMoreBtn: true
			},
			//skipoffer joint
			consent3_joint: {
				text:'Skip offer joint',
				required: true,
				consents: ['eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			consent1_prescreen: {
				text:'',
				required: true,
				consents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
				readMoreBtn: false
			},
			consent2_prescreen: {
				text:'',
				required: true,
				consents: 'ECONSENTANDSOFTINQUIRY,MARKETING,ECONSENT,SOFT_INQUIRY',
				readMoreBtn: false
			},

		},
		teller: {
			consent1_individual: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
					you consent, acknowledge, and agree to the following:
						<ul>
						<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
						<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
						<li>You are providing express written consent under the Fair Credit Reporting Act to authorize ABC to
						 obtain your credit profile from credit reporting agencies. You authorize ABC to obtain such
						 information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is
						  not a final application for credit.</li>
						</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_individual: {
				text:'Marketing Consent',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			//Skip offer/Cash apps
			consent3_individual: {
				text:`By providing information about yourself and clicking the box to the left, you consent, acknowledge, and agree to the following:
					<ul>
					<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
					<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
					</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			//preapproval
			consent4_individual: {
				text:'preapproval consent',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			consent1_joint: {
				text:`By providing information about yourself in this pre-qualification and selecting the box to the left,
					you consent, acknowledge, and agree to the following:
				<ul>
				<li>Consent for use of <a href="/disclosure" target="_blank">E-Signature and Electronic Disclosure Agreement</a></li>
				<li>Consent to <a href="/security-policy" target="_blank">Security Policy</a></li>
				<li>You are providing express written consent under the Fair Credit Reporting Act on behalf of yourself and one or more co-borrowers
				to authorize ABC to obtain your and one or more co-borrowers credit profile from credit reporting agencies. You authorize ABC to obtain
				such information solely to evaluate your eligibility for certain loan product pre-qualification offers and acknowledge that this is not a
				final application for credit.</li>
				</ul>`,
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: true
			},
			consent2_joint: {
				text:'',
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
			//skipoffer joint
			consent3_joint: {
				text:"By selecting the box to the left, you agree that completing and submitting information on behalf " +
					"of yourself and one or more co-borrowers indicates your intent to apply for joint credit.",
				required: true,
				consents: ['eConsentAndSoftInquiry','Marketing','eConsent','SoftInQuiry'],
				readMoreBtn: false
			},
		},

	},
	underwritingStatusMessages: {
		approved: {
			title: "Congratulations! Your application is now approved.",
			message: "Our team will reach out to you within the next business day with next steps. Thank you for your membership in ABC Credit Union."
		},
		pending: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		},
		decline: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		},
		docRequest: {
			title: "Documents Required For Application Processing",
			message: "Additional documents are required to successfully complete the processing of your application. Please upload the below documents."
		},
		default: {
			title: "Your application is being processed.",
			message: "Our team will reach out to you within the next business day to review your application."
		}
	},
	crossSellOffersPage: {
		title: "Congratulations!! We found additional personalized pre-qualified offers for you.",
		message: ""
	},
	footerConfig: {
		footerTop: {
			BGColor: "#5EB3E4",
			FontColor: "#000"
		},
		footerBottom: {
			BGColor: "#5EB3E4",
			FontColor: "#FFF",
			copyRight: "Creditsnap",
			phone: "111-111-1111"
		}
	},
	ssnPrompt: true,
	mailingAddress: true,
	minHousingCost: 350,
	iovationIntegration: false,
	socureAPIIntegration: false,
	crossCoreUserProfile: false,
	defaultIncome: 300,
	minMax: {
		loanAmount: {
			min: 5000,
			max: 9999999
		}
	},
	// fraudCheckOnFinalSubmit: true,
	poboxAllowed: false,
	promoCode: false,
	membershipEligibilityCriteria: {
		visible: true,
		membershipString: null,
		familyMember: true,
		sponsorOrganization: true,
		sponsorOrganizationType: "search",
		live: true,
		work: true,
		worship: false,
		study: true
	},
	membershipEligibilityOptions: [
		{ value: "FAMILY_MEMBER", label: "Someone in my family is a member" },
		{
			value: "FAMILY_MEMBER_AFFILIATED_ORGANIZATION",
			label: "Someone in my family work in one of the sponsor organizations."
		},
		{ value: "AFFILIATED_ORGANIZATION", label: "I work in one of the sponsor organizations" },
		{ value: "ASSOCIATION_MEMBERSHIP_NEW", label: "New Association Membership." },
		{ value: "ASSOCIATION_MEMBERSHIP_EXISTING", label: "Existing Association Membership." },
		{ value: "LIVING_AREA", label: "I live in eligible county" },
		{ value: "WORK_AREA", label: "I work in eligible county" },
		{ value: "WORSHIP_AREA", label: "I worship in eligible county" },
		{ value: "LEARN_AREA", label: "I attend school in eligible county" }
	],
	redeemCodeFields: {
		lastName: true,
		zipCode: false,
		ssn: false,
		dob: true
	},
	residenceStatusList: [
		{ value: "OWN_MORTGAGE", label: "Mortgage" },
		{ value: "RENT", label: "Rent" },
		{ value: "OWN_FREE_CLEAR", label: "Own Free and Clear" },
		{ value: "LIVING_WITH_PARENTS", label: "Living with Parents" },
		{ value: "LIVING_WITH_SOMEONE", label: "Living with someone" },
		{ value: "OTHER", label: "Other" }
	],
	additionalIncome: {
		startDate: false,
		types: [
			{
				id: "AL",
				text: "Alimony"
			},
			{
				id: "CS",
				text: "Child Support"
			},
			{
				id: "I",
				text: "Investment Income"
			},
			{
				id: "S",
				text: "Social Security"
			},
			{
				id: "R",
				text: "Retirement Income"
			},
			{
				id: "RP",
				text: "Rental Property Income"
			},
			{
				id: "O",
				text: "Other"
			}
		]
	},
	companyEntityType: [
		{value: 'LLC', name: 'Limited Liability Company'},
		{value: 'CCORP', name: 'C Corporation'},
		{value: 'SCORP', name: 'S Corporation'},
		{value: 'LP', name: 'Limited Partnership'},
		{value: 'GP', name: 'General Partnership'},
		{value: 'SP', name: 'Sole Proprietorship'},
		{value: 'NPC', name: 'Non Profit Corporation'},
		{value: 'NPA', name: 'Non Profit Association'},
		{value: 'UNINC', name: 'Unincorporated Organization'},
		{value: 'TRUST', name: 'Trust'},
		{value: 'RO', name: 'Religious Organization' },
		{value: 'MUNI', name: 'Municipality' },
		{value: 'GE', name: 'Government Entity' },
		{value: 'OTHER', name: 'Other'},
	],
	loanPurpose: {
	},
	steps: {
		1: {
			title: "Personal Details",
			fields: {
				name: true,
				email: false
			}
		},
		2: {
			title: "Bank Details",
			fields: {
				phone: true,
				address: false
			}
		},
		3: {
			title: "Extra details"
		}
	},

	loanCategories: {
	}
};
