import {
	Component,
	EventEmitter,
	forwardRef,
	HostListener,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	NgControl,
	ValidationErrors,
	Validator
} from '@angular/forms';
import {IntlTelInputComponent} from './intl-tel-input/intl-tel-input.component';
import {PhoneNumberOptionModel} from './phone-number-option.model';

@Component({
	selector: 'creditsnap-phone-input',
	templateUrl: './phone-input.component.html',
	styleUrls: ['./phone-input.component.scss'],
	providers: [{
		provide: NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => PhoneInputComponent),
		multi: true
	}, {
		provide: NG_VALIDATORS,
		useExisting: forwardRef(() => PhoneInputComponent),
		multi: true
	}]
})
export class PhoneInputComponent implements ControlValueAccessor, Validator, OnChanges{
	@ViewChild('phoneInput', {read: NgControl}) phoneElement: NgControl;
	@ViewChild(IntlTelInputComponent) intlTelInputCtrl: IntlTelInputComponent | undefined;

	@Input() id = 'phone';
	// @Input() name = 'phone';
	// @Input() label = 'Phone Number';
	// @Input() phoneNotRequired = false;
	@Input() required = true;
	@Input() placeholder: string;
	@Input() prefilled: boolean = false;
	@Input() label: string = 'Phone Number';
	@Input() options: PhoneNumberOptionModel = new PhoneNumberOptionModel();
	// @Output() isValid: EventEmitter<boolean> = new EventEmitter<false>();
	@Output() valueChange: EventEmitter<string> = new EventEmitter<string>();
	@Output() phoneDataEvent: EventEmitter<any> = new EventEmitter();
	value: string;
	phoneNumber: any;

	constructor() {
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.submitted && changes.submitted.currentValue && this.phoneElement) {
			this.phoneElement.control.markAsTouched();
		}
		if (changes.placeholder && changes.placeholder.currentValue) {
			this.options.inputPlaceholder = changes.placeholder.currentValue;
		}
		if (changes.prefilled && changes.prefilled.currentValue) {
			this.prefilled = !!changes.prefilled.currentValue;
		}
		if (changes.id && changes.id.currentValue) {
			this.id = changes.id.currentValue;
		}
	}

	validate(control: AbstractControl): ValidationErrors | null {
		return this.phoneElement?.control.errors;
	}
	// validate(): ValidationErrors | null {
	// 	return this.phoneValidator();
	// }

	// @HostListener('keydown.backspace', ['$event'])
	// keydownBackspace(event: any) {
	// 	this.onInput(event, true);
	// }


	onChange = (_: string) => {
		console.log(_);
	};

	onTouched = () => {
	};

	/**
	 * Method that is invoked on an update of a model.
	 */
	updateChanges(value) {
		this.onChange(value);
	}

	touch() {
		this.phoneElement.control.markAsTouched();
		// this.phoneValidator();
	}

	///////////////
	// OVERRIDES //
	///////////////

	/**
	 * Writes a new item to the element.
	 * @param value the value
	 */
	writeValue(value: string): void {
		this.value = value;
		setTimeout(() => {
			this.updateChanges(value);
		}, 50)

	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}


	@HostListener('blur')
	@HostListener('focusout')
	@HostListener('input')
	handleClick() {
		this.handleValue();
	}

	private handleValue() {
		//if (this.intlTelInputCtrl.phoneNumber) {
			this.writeValue(this.intlTelInputCtrl.value);
		//}
	}

	editPrefilledNumber() {
		this.prefilled = false;
		this.writeValue(null);
	}
}
