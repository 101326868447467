export class ConsentModel {
	applicationId: number;
	consentId: number;
	softInquiryConsent: boolean;
	consent1_individual: boolean;
	consent2_individual: boolean;
	consent3_individual: boolean;
	consent4_individual: boolean;
	consent1_joint: boolean;
	consent2_joint: boolean;
	consent3_joint: boolean;
	preScreenConsent: boolean;
	preScreenConsentDate: Date;
	softInquiryConsentDate: Date;
	marComConsent: boolean;
	marComConsentDate: Date;
	emailVerified: boolean;
	smsVerified: boolean;
	hardInquiryConsent: boolean;
	hardInquiryConsentDate: Date;
	emailUnsubscribe: boolean;
	emailUnsubscribeDate: Date;
	smsUnsubscribe: Date;
	smsUnsubscribeDate: Date;
	jointConsent: boolean;
	jointConsentDate: Date;
	consent3_joint_skip_offer: any;
	consent3JoinSkipOffer: any;
	specialConsent: boolean;
	payoffQuoteConsent: boolean;
	mlaConsent: boolean;

	constructor() {
		this.softInquiryConsent = false;
		this.preScreenConsent = false;
		this.marComConsent = false;
		this.jointConsent = false;
		this.specialConsent = false;
		this.payoffQuoteConsent = false;
		this.mlaConsent = false;
	}
}
