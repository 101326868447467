import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import {
	ApplicantModel,
	ApplicationModel,
	CSPostResponseModel
} from '@creditsnap/data-models';
import { APP_ENV_CONFIG } from '../index';

@Injectable({
	providedIn: 'root'
})
export class ApplicantService {
	API_APPLICANT_URL = '';
	API_APPLICATION_URL = '';
	API_URL = '';

	constructor(
		private http: HttpClient,
		@Inject(APP_ENV_CONFIG) private environment: any
	) {
		this.API_URL = environment.apiUrl;
		this.API_APPLICANT_URL = this.API_URL + '/applicant';
		this.API_APPLICATION_URL = this.API_URL + '/application';
	}

	addApplicant(_applicationId: number, _applicant: ApplicantModel): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.API_APPLICANT_URL + `/${_applicationId}`, _applicant);
	}

	updateApplicant(_applicationId: number, applicant: ApplicantModel): Observable<CSPostResponseModel> {
		return this.http.put<CSPostResponseModel>(this.API_APPLICANT_URL + `/${_applicationId}`, applicant);
	}

	updateCoApplicant(_appId: number, body: any): Observable<any> {
		return this.http.put<any>(this.API_URL + `/coapplicant/${_appId}`, body);
	}

	findApplicant(_applicantId: number, isCoBoSearch = false): Observable<ApplicantModel> {
		const url = (isCoBoSearch) ? this.API_URL + '/coapplicant/details' : this.API_APPLICANT_URL;
		return this.http.get<ApplicantModel>(url + `/${_applicantId}`).pipe(
			retry(3));
	}

	// the applicants will be part of the data attribute, this can be one or collection
	findLoanApplicants(_applicantionId: number): Observable<any> {
		return this.http.get<ApplicationModel>(this.API_APPLICANT_URL + `/${_applicantionId}`).pipe(
			retry(3));
	}

	getReference(_applicationId: number): Observable<any> {
		return this.http.get<any>(this.API_APPLICATION_URL + `/${_applicationId}/reference`);
	}

	updateReference(_applicationId: number, referenceBody: any): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.API_APPLICATION_URL + `/${_applicationId}/reference`, referenceBody);
	}

	updatePastResidence(_applicationId: number, residenceBoy: any): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.API_APPLICANT_URL + `/pastresidence/${_applicationId}`, residenceBoy);
	}

	updateDeclarationQuestion(declarationBody: any): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/declaration`, declarationBody);
	}

	getDeclarationQuestion(_applicationId: number): Observable<any> {
		return this.http.get<any>(this.environment.apiUrl + `/declaration/${_applicationId}`);
	}

	updateAssetsLiabilities(assetsLiabilities: any): Observable<CSPostResponseModel> {
		return this.http.post<CSPostResponseModel>(this.environment.apiUrl + `/financialcondition`, assetsLiabilities);
	}

	getAssetsLiabilities(_applicationId: number): Observable<any> {
		return this.http.get<any>(this.environment.apiUrl + `/financialcondition/${_applicationId}`);
	}

	deleteAdditionalIncome(_applicantId: number, _additionalIncomeId: any): Observable<any> {
		return this.http.delete<any>(this.API_APPLICANT_URL + `/additionalincome/${_applicantId}/${_additionalIncomeId}`);
	}

	searchNaicsCompanyByName(s: string): Observable<any> {
		const url = 'https://7MV7A9XWSE.algolia.net/1/indexes/NAICS/query';
		const body = {
			query: `${s}`,
			filters: "institutionlookup:NAICS_Lookup",
			attributesToRetrieve: [
				"code",
				"value"
			]
		}

		return this.http.post(url, body, {
			headers: {
				'X-Skip-Headers': this.environment.auth.interceptorSkipHeader,
				'X-Algolia-Api-Key': this.environment.ALGOLIA_API_KEY,
				'X-Algolia-Application-Id': this.environment.ALGOLIA_APPLICATION_ID
			}
		});
	}

}
