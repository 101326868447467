export class ResidenceModel {
    applicationId: number;
    residenceId: number;
    status: string; // Own, Renting
    position: string;
    cost: number; // Rent, Mortgage
    costString: string;
    schedule: string; // Annually, Monthly
    length: number; // Length of Residence
    years: number;
    months: number;
    currentResidence: boolean;

    constructor() {
        this.schedule = 'MONTHLY';
    }
}
