import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {RadioOptionModel} from './radio-option.model';
import { RadioTemplate } from '@creditsnap/data-models';

@Component({
    selector: 'creditsnap-radio',
    templateUrl: './radio-group.component.html',
    styleUrls: ['./radio-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RadioGroupComponent implements OnChanges {
    @Input() radioArray: RadioOptionModel;
    @Input() radioGroupModel: string;
	@Output() selectionChange = new EventEmitter();
	@Output() derosDateEmitter = new EventEmitter();

	/* used for searchable radio option */
    @Output() searchValue = new EventEmitter();

    RadioTemplate = RadioTemplate;
    searchSelectedValue: Array<string> = [];
    selectionComplete: Array<boolean> = [];

    constructor(private cdr: ChangeDetectorRef) {
        if (this.radioArray && !this.radioArray.subProductTemplate) {
            this.radioArray.subProductTemplate = RadioTemplate.RadioCard;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.radioArray && changes.radioArray.currentValue) {
            this.radioArray = changes.radioArray.currentValue;
            if (this.radioArray && !this.radioArray.subProductTemplate) {
                this.radioArray.subProductTemplate = RadioTemplate.RadioCard;
            }
            // this.cdr.detectChanges();
        }
        this.radioArray.items.forEach(item => {
            if (item.heading === 'Other') {
              item.otherText.value = '';
            }
          });
    }

    sendSearchValue(e, radioValue, radioIndex) {
        this.selectionComplete[radioIndex] = undefined;
        this.searchValue.emit({event: e.target.value, radioValue});
        this.radioArray.items[radioIndex].search.value = e.target.value;
        this.selectionChange.emit(this.radioArray.items.find(item => item.value.toString() === this.radioGroupModel.toString()));
    }

    sendValue() {
        this.radioArray.items.map((item, i) => {
            if (item.search && item.value !== this.radioGroupModel) {
                this.searchSelectedValue[i] = undefined;
                this.selectionComplete[i] = undefined;
                if (item.search.value) {
                    item.search.value = undefined;
                }
            }
        });
        this.selectionChange.emit(this.radioArray.items.find(item => item.value.toString() === this.radioGroupModel.toString()));
    }

    highlightedText(text, searchString) {
        const regEx = new RegExp(searchString, 'ig');
        const replaceMask = '<span class="match">' + searchString + '</span>';

        return text.replace(regEx, replaceMask);
    }

    selectSearchValue(result, radioIndex) {
        this.searchSelectedValue[radioIndex] = result.title;
        this.selectionComplete[radioIndex] = true;
        this.radioArray.items[radioIndex].search.value = result;
        this.radioArray.items[radioIndex].search.searchValues = [];
        this.sendValue();
    }

    // removed after client requirement
    activeLearnMore(i) {
        this.radioArray.items[i].learnMoreActive = !this.radioArray.items[i].learnMoreActive;
    }
}
