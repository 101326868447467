export enum VehicleTypeEnum {
    Auto = 'AUTO',
    Motorcycle = 'Motorcycle'
}

export enum AutoTypeEnum {
    Used = 'USED',
    New = 'NEW'
}

export enum ApplicationTypeEnum {
    INDIVIDUAL = 'I',
    JOINT = 'J'
}

export enum ApplicantType {
	PRIMARY = 'P',
	COBORROWER = 'S',
	SECONDARY = 'S',
	BUSINESS = 'BS',
	BENEFICIARY = 'B',
	CONTROLLINGMANAGER = 'CM',
	BUSINESS_OWNER = 'O',
	GUARANTOR = 'G',
	AUTHORISEDUSER = 'A',
	EMPLOYEEREFERENCE = 'ER',
	BUSINESSOFFICER = 'BO',
}

export enum SearchSystem {
	Prove = 'prove',
	Core = 'core'
}

export enum AutoLoanPurpose {
    R = 'Auto Refinance',
    L = 'Lease Purchase',
    P = 'Company Car Purchase',
    C = 'Company Car Purchase Cash',
    LC = 'Lease Purchase Cash',
    AN = 'Auto New Purchase',
    AU = 'Auto Used Purchase',
    PC = 'Credit Card',
    HE = 'Home Equity',
    PP = 'Personal Loan'
}

export enum ApplicationStatusEnum {
    PRIMARY_APPLICANT_ADDED = 'PRIMARY_APPLICANT_ADDED',
    APPLICATION_SUBMITTED = 'APPLICATION_SUBMITTED',
    ALL_APPLICANTS_ADDED = 'ALL_APPLICANTS_ADDED',
    CASH_APPLICATION_SUBMITTED = 'CASH_APPLICATION_SUBMITTED',
    NO_OFFER_FOUND = 'NO_OFFER_FOUND',
    NO_OFFER_HARD_DECLINE = 'NO_OFFER_HARD_DECLINE',
    NO_OFFER_SOFT_DECLINE = 'NO_OFFER_SOFT_DECLINE',
    NO_OFFER_FRAUD_DECLINE = 'NO_OFFER_FRAUD_DECLINE',
    FRAUD = 'FRAUD',
    FINANCIAL_SUBMITTED_FRAUD = 'FINANCIAL_SUBMITTED_FRAUD',
    FINANCIAL_SUBMITTED_FRAUD_REVIEW = 'FINANCIAL_SUBMITTED_FRAUD_REVIEW',
    FINANCIAL_SUBMITTED_FRAUD_DECLINE = 'FINANCIAL_SUBMITTED_FRAUD_DECLINE',
    NO_OFFER_PEND = 'PEND',
    NO_OFFER_PRE_FAIL = 'NO_OFFER_PRE_FAIL', // Pre-eligibility failed
    OFFER_GENERATION_INITIATED = 'OFFER_GENERATION_INITIATED',
    TRADELINE_SELECTED = 'TRADELINE_SELECTED',
    OFFER_GENERATED = 'OFFER_GENERATED',
    OFFER_GENERATED_WITH_LTV = 'OFFER_GENERATED_WITH_LTV',
    OFFER_SELECTED = 'OFFER_SELECTED',
    OFFER_CUSTOMIZED = 'OFFER_CUSTOMIZED',
    OFFER_DECLINED = 'OFFER_DECLINED',
    OFFER_EXPIRED = 'OFFER_EXPIRED',
    NO_BUREAU_REPORT = 'NO_BUREAU_REPORT',
    COLLATERAL_ADDED = 'COLLATERAL_ADDED',
    COLLATERAL_VALUE_RECEIVED = 'COLLATERAL_VALUE_RECEIVED',
    COLLATERAL_PROPERTY_UPDATE = 'COLLATERAL_PROPERTY_UPDATE',
    COLLATERAL_TRADELINE_ADDED = 'COLLATERAL_TRADELINE_ADDED',
    COLLATERAL_REQUEST_FAILED = 'COLLATERAL_REQUEST_FAILED',
    ADDITIONAL_INFO_ADDED = 'ADDITIONAL_INFO_ADDED',
    FINANCIAL_SUBMITTED = 'FINANCIAL_SUBMITTED',
    PROSPECT_APPLICATION = 'PROSPECT_APPLICATION',
    FINANCIAL_SUBMITTED_FAILED = 'FINANCIAL_SUBMITTED_FAILED',
    PRE_FINANCIAL_SUBMITTED = 'PRE_FINANCIAL_SUBMITTED',
    APPLICATION_FAILED = 'APPLICATION_FAILED',
    APPLICATION_WITHDRAWN = 'APPLICATION_WITHDRAWN',
    BUREAU_REPORT_RECEIVED = 'BUREAU_REPORT_RECEIVED',
    NO_BUREAU_HIT_COBORROWER = 'NO_BUREAU_HIT_COBORROWER',
	XPN_BUREAU_REPORT_LOCKED = 'XPN_BUREAU_REPORT_LOCKED',
	TU_BUREAU_REPORT_LOCKED = 'TU_BUREAU_REPORT_LOCKED',
	NO_OFFER_NO_HIT = 'NO_OFFER_NO_HIT',
	OTP_VERIFICATION_PENDING = 'OTP_VERIFICATION_PENDING',
}

export enum ProvisionStatusEnum {
    UNDERWRITING_PEND = 'UNDERWRITING_PEND',
    UNDERWRITING_DECLINE = 'UNDERWRITING_DECLINE',
    UNDERWRITING_APPROVED = 'UNDERWRITING_APPROVED',
    DOCS_STARTED = 'DOCS_STARTED',
    DOCS_SIGNED = 'DOCS_SIGNED',
    VERIFICATION_STARTED = 'VERIFICATION_STARTED',
    VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS',
    VERIFICATION_FAILED = 'VERIFICATION_FAILED',
    INCOME_VERIFICATION_STARTED = 'INCOME_VERIFICATION_STARTED',
    INCOME_VERIFICATION_SUCCESS = 'INCOME_VERIFICATION_SUCCESS',
    INCOME_VERIFICATION_FAILED = 'INCOME_VERIFICATION_FAILED',
    PAYMENT_SUBMITTED = 'PAYMENT_SUBMITTED',
    PAYMENT_SKIPPED = 'PAYMENT_SKIPPED',

}

export const StateList = [
    {
        name: 'Alabama',
        code: 'AL'
    },
    {
        name: 'Alaska',
        code: 'AK'
    },
    {
        name: 'Arizona',
        code: 'AZ'
    },
    {
        name: 'Arkansas',
        code: 'AR'
    },
    {
        name: 'California',
        code: 'CA'
    },
    {
        name: 'Colorado',
        code: 'CO'
    },
    {
        name: 'Connecticut',
        code: 'CT'
    },
    {
        name: 'District of Columbia',
        code: 'DC'
    },
    {
        name: 'Delaware',
        code: 'DE'
    },
    {
        name: 'Florida',
        code: 'FL'
    },
    {
        name: 'Georgia',
        code: 'GA'
    },
    {
        name: 'Hawaii',
        code: 'HI'
    },
    {
        name: 'Idaho',
        code: 'ID'
    },
    {
        name: 'Illinois',
        code: 'IL'
    },
    {
        name: 'Indiana',
        code: 'IN'
    },
    {
        name: 'Iowa',
        code: 'IA'
    },
    {
        name: 'Kansas',
        code: 'KS'
    },
    {
        name: 'Kentucky',
        code: 'KY'
    },
    {
        name: 'Louisiana',
        code: 'LA'
    },
    {
        name: 'Maine',
        code: 'ME'
    },
    {
        name: 'Maryland',
        code: 'MD'
    },
    {
        name: 'Massachusetts',
        code: 'MA'
    },
    {
        name: 'Michigan',
        code: 'MI'
    },
    {
        name: 'Minnesota',
        code: 'MN'
    },
    {
        name: 'Mississippi',
        code: 'MS'
    },
    {
        name: 'Missouri',
        code: 'MO'
    },
    {
        name: 'Montana',
        code: 'MT'
    },
    {
        name: 'Nebraska',
        code: 'NE'
    },
    {
        name: 'Nevada',
        code: 'NV'
    },
    {
        name: 'New Hampshire',
        code: 'NH'
    },
    {
        name: 'New Jersey',
        code: 'NJ'
    },
    {
        name: 'New Mexico',
        code: 'NM'
    },
    {
        name: 'New York',
        code: 'NY'
    },
    {
        name: 'North Carolina',
        code: 'NC'
    },
    {
        name: 'North Dakota',
        code: 'ND'
    },
    {
        name: 'Ohio',
        code: 'OH'
    },
    {
        name: 'Oklahoma',
        code: 'OK'
    },
    {
        name: 'Oregon',
        code: 'OR'
    },
    {
        name: 'Pennsylvania',
        code: 'PA'
    },
    {
        name: 'Rhode Island',
        code: 'RI'
    },
    {
        name: 'South Carolina',
        code: 'SC'
    },
    {
        name: 'South Dakota',
        code: 'SD'
    },
    {
        name: 'Tennessee',
        code: 'TN'
    },
    {
        name: 'Texas',
        code: 'TX'
    },
    {
        name: 'Utah',
        code: 'UT'
    },
    {
        name: 'Vermont',
        code: 'VT'
    },
    {
        name: 'Virginia',
        code: 'VA'
    },
    {
        name: 'Washington',
        code: 'WA'
    },
    {
        name: 'West Virginia',
        code: 'WV'
    },
    {
        name: 'Wisconsin',
        code: 'WI'
    },
    {
        name: 'Wyoming',
        code: 'WY'
    },
    {
        name: 'Armed Forces Americas (except Canada)',
        code: 'AA',
        excludeStreetNumberValidation: true
    },
    {
        name: 'Armed Forces Africa',
        code: 'AE',
        excludeStreetNumberValidation: true
    },
    {
        name: 'Armed Forces Canada',
        code: 'AE',
        excludeStreetNumberValidation: true
    },
    {
        name: 'Armed Forces Europe',
        code: 'AE',
        excludeStreetNumberValidation: true
    },
    {
        name: 'Armed Forces Middle East',
        code: 'AE',
        excludeStreetNumberValidation: true
    },
    {
        name: 'Armed Forces Pacific',
        code: 'AP',
        excludeStreetNumberValidation: true
    }
];


export const CountryList = [
	{
		id: 4,
		alpha2: 'AF',
		alpha3: 'AFG',
		name: 'Afghanistan',
		zipRegex: '^\\d{4}$',
		dialCode: 93
	},
	{
		id: 8,
		alpha2: 'AL',
		alpha3: 'ALB',
		name: 'Albania',
		zipRegex: '^\\d{4}$',
		dialCode: 355
	},
	{
		id: 12,
		alpha2: 'DZ',
		alpha3: 'DZA',
		name: 'Algeria',
		zipRegex: '^\\d{5}$',
		dialCode: 213
	},
	{
		id: 16,
		alpha2: 'AS',
		alpha3: 'ASM',
		name: 'American Samoa',
		zipRegex: '^\\d{5}(-{1}\\d{4,6})$',
		dialCode: 1684
	},
	{
		id: 20,
		alpha2: 'AD',
		alpha3: 'AND',
		name: 'Andorra',
		zipRegex: '^[Aa][Dd]\\d{3}$',
		dialCode: 376
	},
	{
		id: 24,
		alpha2: 'AO',
		alpha3: 'AGO',
		name: 'Angola',
		zipRegex: 0,
		dialCode: 244
	},
	{
		id: 660,
		alpha2: 'AI',
		alpha3: 'AIA',
		name: 'Anguilla',
		zipRegex: '^[Aa][I][-][2][6][4][0]$',
		dialCode: 1264
	},
	{
		id: 10,
		alpha2: 'AQ',
		alpha3: 'ATA',
		name: 'Antarctica',
		zipRegex: 0
	},
	{
		id: 28,
		alpha2: 'AG',
		alpha3: 'ATG',
		name: 'Antigua and Barbuda',
		zipRegex: 0,
		dialCode: 1268
	},
	{
		id: 32,
		alpha2: 'AR',
		alpha3: 'ARG',
		name: 'Argentina',
		zipRegex: '^\\d{4}|[A-Za-z]\\d{4}[a-zA-Z]{3}$',
		dialCode: 54
	},
	{
		id: 51,
		alpha2: 'AM',
		alpha3: 'ARM',
		name: 'Armenia',
		zipRegex: '^\\d{4}$',
		dialCode: 374
	},
	{
		id: 533,
		alpha2: 'AW',
		alpha3: 'ABW',
		name: 'Aruba',
		zipRegex: "",
		dialCode: 297
	},
	{
		id: 36,
		alpha2: 'AU',
		alpha3: 'AUS',
		name: 'Australia',
		zipRegex: '^\\d{4}$',
		dialCode: 61
	},
	{
		id: 40,
		alpha2: 'AT',
		alpha3: 'AUT',
		name: 'Austria',
		zipRegex: '^\\d{4}$',
		dialCode: 43
	},
	{
		id: 31,
		alpha2: 'AZ',
		alpha3: 'AZE',
		name: 'Azerbaijan',
		zipRegex: '^[Aa][Zz]\\d{4}$',
		dialCode: 994
	},
	{
		id: 44,
		alpha2: 'BS',
		alpha3: 'BHS',
		name: 'Bahamas',
		zipRegex: 0,
		dialCode: 1242
	},
	{
		id: 48,
		alpha2: 'BH',
		alpha3: 'BHR',
		name: 'Bahrain',
		zipRegex: '^\\d{3,4}$',
		dialCode: 973
	},
	{
		id: 50,
		alpha2: 'BD',
		alpha3: 'BGD',
		name: 'Bangladesh',
		zipRegex: '^\\d{4}$',
		dialCode: 880
	},
	{
		id: 52,
		alpha2: 'BB',
		alpha3: 'BRB',
		name: 'Barbados',
		zipRegex: '^[Aa][Zz]\\d{5}$',
		dialCode: 1246
	},
	{
		id: 112,
		alpha2: 'BY',
		alpha3: 'BLR',
		name: 'Belarus',
		zipRegex: '^\\d{6}$',
		dialCode: 375
	},
	{
		id: 56,
		alpha2: 'BE',
		alpha3: 'BEL',
		name: 'Belgium',
		zipRegex: '^\\d{4}$',
		dialCode: 32
	},
	{
		id: 84,
		alpha2: 'BZ',
		alpha3: 'BLZ',
		name: 'Belize',
		zipRegex: 0,
		dialCode: 501
	},
	{
		id: 204,
		alpha2: 'BJ',
		alpha3: 'BEN',
		name: 'Benin',
		zipRegex: 0,
		dialCode: 229
	},
	{
		id: 60,
		alpha2: 'BM',
		alpha3: 'BMU',
		name: 'Bermuda',
		zipRegex: '^[A-Za-z]{2}\\s([A-Za-z]{2}|\\d{2})$',
		dialCode: 1441
	},
	{
		id: 64,
		alpha2: 'BT',
		alpha3: 'BTN',
		name: 'Bhutan',
		zipRegex: '^\\d{5}$',
		dialCode: 975
	},
	{
		id: 68,
		alpha2: 'BO',
		alpha3: 'BOL',
		name: 'Bolivia',
		zipRegex: '^\\d{4}$',
		dialCode: 591
	},
	{
		id: 535,
		alpha2: 'BQ',
		alpha3: 'BES',
		name: 'Bonaire, Sint Eustatius and Saba',
		zipRegex: 0,
		dialCode: 599
	},
	{
		id: 70,
		alpha2: 'BA',
		alpha3: 'BIH',
		name: 'Bosnia and Herzegovina',
		zipRegex: '^\\d{5}$',
		dialCode: 387
	},
	{
		id: 72,
		alpha2: 'BW',
		alpha3: 'BWA',
		name: 'Botswana',
		zipRegex: '',
		dialCode: 267
	},
	{
		id: 74,
		alpha2: 'BV',
		alpha3: 'BVT',
		name: 'Bouvet Island',
		zipRegex: 0
	},
	{
		id: 76,
		alpha2: 'BR',
		alpha3: 'BRA',
		name: 'Brazil',
		zipRegex: '^\\d{5}-\\d{3}$',
		dialCode: 55
	},
	{
		id: 86,
		alpha2: 'IO',
		alpha3: 'IOT',
		name: 'British Indian Ocean Territory',
		zipRegex: '^[Bb]{2}[Nn][Dd]\\s{0,1}[1][Zz]{2}$',
		dialCode: 246
	},
	{
		id: 96,
		alpha2: 'BN',
		alpha3: 'BRN',
		name: 'Brunei Darussalam',
		zipRegex: '^[A-Za-z]{2}\\d{4}$',
		dialCode: 673
	},
	{
		id: 100,
		alpha2: 'BG',
		alpha3: 'BGR',
		name: 'Bulgaria',
		zipRegex: '^\\d{4}$',
		dialCode: 359
	},
	{
		id: 854,
		alpha2: 'BF',
		alpha3: 'BFA',
		name: 'Burkina Faso',
		zipRegex: '',
		dialCode: 226
	},
	{
		id: 108,
		alpha2: 'BI',
		alpha3: 'BDI',
		name: 'Burundi',
		zipRegex: '',
		dialCode: 257
	},
	{
		id: 132,
		alpha2: 'CV',
		alpha3: 'CPV',
		name: 'Cabo Verde',
		zipRegex: '^\\d{4}$',
		dialCode: 238
	},
	{
		id: 116,
		alpha2: 'KH',
		alpha3: 'KHM',
		name: 'Cambodia',
		zipRegex: '^\\d{5}$',
		dialCode: 855
	},
	{
		id: 120,
		alpha2: 'CM',
		alpha3: 'CMR',
		name: 'Cameroon',
		zipRegex: '',
		dialCode: 237
	},
	{
		id: 124,
		alpha2: 'CA',
		alpha3: 'CAN',
		name: 'Canada',
		zipRegex: '(^(?:[A-Z]\\d[A-Z][ -]?\\d[A-Z]\\d)$)',
		dialCode: 1
	},
	{
		id: 136,
		alpha2: 'KY',
		alpha3: 'CYM',
		name: 'Cayman Islands',
		zipRegex: '^[Kk][Yy]\\d[-\\s]{0,1}\\d{4}$',
		dialCode: 1345
	},
	{
		id: 140,
		alpha2: 'CF',
		alpha3: 'CAF',
		name: 'Central African Republic',
		zipRegex: 0,
		dialCode: 236
	},
	{
		id: 148,
		alpha2: 'TD',
		alpha3: 'TCD',
		name: 'Chad',
		zipRegex: '^\\d{5}$',
		dialCode: 235
	},
	{
		id: 152,
		alpha2: 'CL',
		alpha3: 'CHL',
		name: 'Chile',
		zipRegex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$',
		dialCode: 56
	},
	{
		id: 156,
		alpha2: 'CN',
		alpha3: 'CHN',
		name: 'China',
		zipRegex: '^\\d{6}$',
		dialCode: 86
	},
	{
		id: 162,
		alpha2: 'CX',
		alpha3: 'CXR',
		name: 'Christmas Island',
		zipRegex: '^\\d{4}$',
		dialCode: 61
	},
	{
		id: 166,
		alpha2: 'CC',
		alpha3: 'CCK',
		name: 'Cocos (Keeling) Islands',
		zipRegex: '^\\d{4}$',
		dialCode: 61
	},
	{
		id: 170,
		alpha2: 'CO',
		alpha3: 'COL',
		name: 'Colombia',
		zipRegex: '^\\d{6}$',
		dialCode: 57
	},
	{
		id: 174,
		alpha2: 'KM',
		alpha3: 'COM',
		name: 'Comoros',
		zipRegex: '',
		dialCode: 269
	},
	{
		id: 180,
		alpha2: 'CD',
		alpha3: 'COD',
		name: 'Congo (the Democratic Republic of)',
		zipRegex: '^[Cc][Dd]$',
		dialCode: 243
	},
	{
		id: 178,
		alpha2: 'CG',
		alpha3: 'COG',
		name: 'Congo',
		zipRegex: '',
		dialCode: 242
	},
	{
		id: 184,
		alpha2: 'CK',
		alpha3: 'COK',
		name: 'Cook Islands',
		zipRegex: '',
		dialCode: 682
	},
	{
		id: 188,
		alpha2: 'CR',
		alpha3: 'CRI',
		name: 'Costa Rica',
		zipRegex: '^\\d{4,5}$',
		dialCode: 506
	},
	{
		id: 191,
		alpha2: 'HR',
		alpha3: 'HRV',
		name: 'Croatia',
		zipRegex: '^\\d{5}$',
		dialCode: 385
	},
	{
		id: 192,
		alpha2: 'CU',
		alpha3: 'CUB',
		name: 'Cuba',
		zipRegex: '^\\d{5}$',
		dialCode: 53
	},
	{
		id: 531,
		alpha2: 'CW',
		alpha3: 'CUW',
		name: 'Curaçao',
		zipRegex: '',
		dialCode: 599
	},
	{
		id: 196,
		alpha2: 'CY',
		alpha3: 'CYP',
		name: 'Cyprus',
		zipRegex: '^\\d{4}$',
		dialCode: 357
	},
	{
		id: 203,
		alpha2: 'CZ',
		alpha3: 'CZE',
		name: 'Czechia',
		zipRegex: '^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$',
		dialCode: 420
	},
	{
		id: 384,
		alpha2: 'CI',
		alpha3: 'CIV',
		name: 'Côte d\'Ivoire',
		zipRegex: '',
		dialCode: 225
	},
	{
		id: 208,
		alpha2: 'DK',
		alpha3: 'DNK',
		name: 'Denmark',
		zipRegex: '^\\d{4}$',
		dialCode: 45
	},
	{
		id: 262,
		alpha2: 'DJ',
		alpha3: 'DJI',
		name: 'Djibouti',
		zipRegex: '',
		dialCode: 253
	},
	{
		id: 212,
		alpha2: 'DM',
		alpha3: 'DMA',
		name: 'Dominica',
		zipRegex: '',
		dialCode: 1767
	},
	{
		id: 214,
		alpha2: 'DO',
		alpha3: 'DOM',
		name: 'Dominican Republic',
		zipRegex: '^\\d{5}$',
		dialCode: 1
	},
	{
		id: 218,
		alpha2: 'EC',
		alpha3: 'ECU',
		name: 'Ecuador',
		zipRegex: '^\\d{6}$',
		dialCode: 593
	},
	{
		id: 818,
		alpha2: 'EG',
		alpha3: 'EGY',
		name: 'Egypt',
		zipRegex: '^\\d{5}$',
		dialCode: 20
	},
	{
		id: 222,
		alpha2: 'SV',
		alpha3: 'SLV',
		name: 'El Salvador',
		zipRegex: '^1101$',
		dialCode: 503
	},
	{
		id: 226,
		alpha2: 'GQ',
		alpha3: 'GNQ',
		name: 'Equatorial Guinea',
		zipRegex: '',
		dialCode: 240
	},
	{
		id: 232,
		alpha2: 'ER',
		alpha3: 'ERI',
		name: 'Eritrea',
		zipRegex: '',
		dialCode: 291
	},
	{
		id: 233,
		alpha2: 'EE',
		alpha3: 'EST',
		name: 'Estonia',
		zipRegex: '^\\d{5}$',
		dialCode: 372
	},
	{
		id: 748,
		alpha2: 'SZ',
		alpha3: 'SWZ',
		name: 'Eswatini',
		zipRegex: '^[A-Za-z]\\d{3}$',
		dialCode: 268
	},
	{
		id: 231,
		alpha2: 'ET',
		alpha3: 'ETH',
		name: 'Ethiopia',
		zipRegex: '^\\d{4}$',
		dialCode: 251
	},
	{
		id: 238,
		alpha2: 'FK',
		alpha3: 'FLK',
		name: 'Falkland Islands (Malvinas)',
		zipRegex: '^[Ff][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$',
		dialCode: 500
	},
	{
		id: 234,
		alpha2: 'FO',
		alpha3: 'FRO',
		name: 'Faroe Islands',
		zipRegex: '^\\d{3}$',
		dialCode: 298
	},
	{
		id: 242,
		alpha2: 'FJ',
		alpha3: 'FJI',
		name: 'Fiji',
		zipRegex: 0,
		dialCode: 679
	},
	{
		id: 246,
		alpha2: 'FI',
		alpha3: 'FIN',
		name: 'Finland',
		zipRegex: '^\\d{5}$',
		dialCode: 358
	},
	{
		id: 250,
		alpha2: 'FR',
		alpha3: 'FRA',
		name: 'France',
		zipRegex: '^\\d{5}$',
		dialCode: 33
	},
	{
		id: 254,
		alpha2: 'GF',
		alpha3: 'GUF',
		name: 'French Guiana',
		zipRegex: '^973\\d{2}$',
		dialCode: 594
	},
	{
		id: 258,
		alpha2: 'PF',
		alpha3: 'PYF',
		name: 'French Polynesia',
		zipRegex: '^987\\d{2}$',
		dialCode: 689
	},
	{
		id: 260,
		alpha2: 'TF',
		alpha3: 'ATF',
		name: 'French Southern Territories',
		zipRegex: '',
		dialCode: 262
	},
	{
		id: 266,
		alpha2: 'GA',
		alpha3: 'GAB',
		name: 'Gabon',
		zipRegex: '^\\d{2}\\s[a-zA-Z-_ ]\\s\\d{2}$',
		dialCode: 241
	},
	{
		id: 270,
		alpha2: 'GM',
		alpha3: 'GMB',
		name: 'Gambia',
		zipRegex: '',
		dialCode: 220
	},
	{
		id: 268,
		alpha2: 'GE',
		alpha3: 'GEO',
		name: 'Georgia',
		zipRegex: '^\\d{4}$',
		dialCode: 995
	},
	{
		id: 276,
		alpha2: 'DE',
		alpha3: 'DEU',
		name: 'Germany',
		zipRegex: '(^\\d{5}$)',
		dialCode: 49
	},
	{
		id: 288,
		alpha2: 'GH',
		alpha3: 'GHA',
		name: 'Ghana',
		zipRegex: '',
		dialCode: 233
	},
	{
		id: 292,
		alpha2: 'GI',
		alpha3: 'GIB',
		name: 'Gibraltar',
		zipRegex: '^[Gg][Xx][1]{2}\\s{0,1}[1][Aa]{2}$',
		dialCode: 350
	},
	{
		id: 300,
		alpha2: 'GR',
		alpha3: 'GRC',
		name: 'Greece',
		zipRegex: '(^\\d{3}\\s{0,1}\\d{2}$)',
		dialCode: 30
	},
	{
		id: 304,
		alpha2: 'GL',
		alpha3: 'GRL',
		name: 'Greenland',
		zipRegex: '^\\d{4}$',
		dialCode: 299
	},
	{
		id: 308,
		alpha2: 'GD',
		alpha3: 'GRD',
		name: 'Grenada',
		zipRegex: '',
		dialCode: 1473
	},
	{
		id: 312,
		alpha2: 'GP',
		alpha3: 'GLP',
		name: 'Guadeloupe',
		zipRegex: '^971\\d{2}$',
		dialCode: 590
	},
	{
		id: 316,
		alpha2: 'GU',
		alpha3: 'GUM',
		name: 'Guam',
		zipRegex: '^\\d{5}$',
		dialCode: 1671
	},
	{
		id: 320,
		alpha2: 'GT',
		alpha3: 'GTM',
		name: 'Guatemala',
		zipRegex: '^\\d{5}$',
		dialCode: 502
	},
	{
		id: 831,
		alpha2: 'GG',
		alpha3: 'GGY',
		name: 'Guernsey',
		zipRegex: '^[A-Za-z]{2}\\d\\s{0,1}\\d[A-Za-z]{2}$',
		dialCode: 44
	},
	{
		id: 324,
		alpha2: 'GN',
		alpha3: 'GIN',
		name: 'Guinea',
		zipRegex: '',
		dialCode: 224
	},
	{
		id: 624,
		alpha2: 'GW',
		alpha3: 'GNB',
		name: 'Guinea-Bissau',
		zipRegex: '^\\d{4}$',
		dialCode: 245
	},
	{
		id: 328,
		alpha2: 'GY',
		alpha3: 'GUY',
		name: 'Guyana',
		zipRegex: '',
		dialCode: 592
	},
	{
		id: 332,
		alpha2: 'HT',
		alpha3: 'HTI',
		name: 'Haiti',
		zipRegex: '^\\d{4}$',
		dialCode: 509
	},
	{
		id: 334,
		alpha2: 'HM',
		alpha3: 'HMD',
		name: 'Heard Island and McDonald Islands',
		zipRegex: '^\\d{4}$'
	},
	{
		id: 336,
		alpha2: 'VA',
		alpha3: 'VAT',
		name: 'Holy See',
		zipRegex: '^120$',
		dialCode: 39
	},
	{
		id: 340,
		alpha2: 'HN',
		alpha3: 'HND',
		name: 'Honduras',
		zipRegex: '^\\d{5}$',
		dialCode: 504
	},
	{
		id: 344,
		alpha2: 'HK',
		alpha3: 'HKG',
		name: 'Hong Kong',
		zipRegex: '',
		dialCode: 852
	},
	{
		id: 348,
		alpha2: 'HU',
		alpha3: 'HUN',
		name: 'Hungary',
		zipRegex: '^\\d{4}$',
		dialCode: 36
	},
	{
		id: 352,
		alpha2: 'IS',
		alpha3: 'ISL',
		name: 'Iceland',
		zipRegex: '^\\d{3}$',
		dialCode: 354
	},
	{
		id: 356,
		alpha2: 'IN',
		alpha3: 'IND',
		name: 'India',
		zipRegex: '^\\d{6}$',
		dialCode: 91
	},
	{
		id: 360,
		alpha2: 'ID',
		alpha3: 'IDN',
		name: 'Indonesia',
		zipRegex: '^\\d{5}$',
		dialCode: 62
	},
	{
		id: 364,
		alpha2: 'IR',
		alpha3: 'IRN',
		name: 'Iran',
		zipRegex: '^\\d{5}-\\d{5}$',
		dialCode: 98
	},
	{
		id: 368,
		alpha2: 'IQ',
		alpha3: 'IRQ',
		name: 'Iraq',
		zipRegex: '^\\d{5}$',
		dialCode: 964
	},
	{
		id: 372,
		alpha2: 'IE',
		alpha3: 'IRL',
		name: 'Ireland',
		zipRegex: '',
		dialCode: 353
	},
	{
		id: 833,
		alpha2: 'IM',
		alpha3: 'IMN',
		name: 'Isle of Man',
		zipRegex: '^[Ii[Mm]\\d{1,2}\\s\\d\\[A-Z]{2}$',
		dialCode: 44
	},
	{
		id: 376,
		alpha2: 'IL',
		alpha3: 'ISR',
		name: 'Israel',
		zipRegex: '^\b\\d{5}(\\d{2})?$',
		dialCode: 972
	},
	{
		id: 380,
		alpha2: 'IT',
		alpha3: 'ITA',
		name: 'Italy',
		zipRegex: '^\\d{5}$',
		dialCode: 39
	},
	{
		id: 388,
		alpha2: 'JM',
		alpha3: 'JAM',
		name: 'Jamaica',
		zipRegex: '^\\d{2}$',
		dialCode: 1
	},
	{
		id: 392,
		alpha2: 'JP',
		alpha3: 'JPN',
		name: 'Japan',
		zipRegex: '^\\d{7}\\s\\(\\d{3}-\\d{4}\\)$',
		dialCode: 81
	},
	{
		id: 832,
		alpha2: 'JE',
		alpha3: 'JEY',
		name: 'Jersey',
		zipRegex: '^[Jj][Ee]\\d\\s{0,1}\\d[A-Za-z]{2}$',
		dialCode: 44
	},
	{
		id: 400,
		alpha2: 'JO',
		alpha3: 'JOR',
		name: 'Jordan',
		zipRegex: '^\\d{5}$',
		dialCode: 962
	},
	{
		id: 398,
		alpha2: 'KZ',
		alpha3: 'KAZ',
		name: 'Kazakhstan',
		zipRegex: '^\\d{6}$',
		dialCode: 7
	},
	{
		id: 404,
		alpha2: 'KE',
		alpha3: 'KEN',
		name: 'Kenya',
		zipRegex: '^\\d{5}$',
		dialCode: 254
	},
	{
		id: 296,
		alpha2: 'KI',
		alpha3: 'KIR',
		name: 'Kiribati',
		zipRegex: 0,
		dialCode: 686
	},
	{
		id: 408,
		alpha2: 'KP',
		alpha3: 'PRK',
		name: 'North Korea',
		zipRegex: 0,
		dialCode: 850
	},
	{
		id: 410,
		alpha2: 'KR',
		alpha3: 'KOR',
		name: 'South Korea',
		zipRegex: '(^\\d{5}$)',
		dialCode: 82
	},
	{
		id: 414,
		alpha2: 'KW',
		alpha3: 'KWT',
		name: 'Kuwait',
		zipRegex: '^\\d{5}$',
		dialCode: 965
	},
	{
		id: 417,
		alpha2: 'KG',
		alpha3: 'KGZ',
		name: 'Kyrgyzstan',
		zipRegex: '^\\d{6}$',
		dialCode: 996
	},
	{
		id: 418,
		alpha2: 'LA',
		alpha3: 'LAO',
		name: 'Lao People\'s Democratic Republic',
		zipRegex: '^\\d{5}$',
		dialCode: 856
	},
	{
		id: 428,
		alpha2: 'LV',
		alpha3: 'LVA',
		name: 'Latvia',
		zipRegex: '^[Ll][Vv][- ]{0,1}\\d{4}$',
		dialCode: 371
	},
	{
		id: 422,
		alpha2: 'LB',
		alpha3: 'LBN',
		name: 'Lebanon',
		zipRegex: '^\\d{4}\\s{0,1}\\d{4}$',
		dialCode: 961
	},
	{
		id: 426,
		alpha2: 'LS',
		alpha3: 'LSO',
		name: 'Lesotho',
		zipRegex: '^\\d{3}$',
		dialCode: 266
	},
	{
		id: 430,
		alpha2: 'LR',
		alpha3: 'LBR',
		name: 'Liberia',
		zipRegex: '^\\d{4}$',
		dialCode: 231
	},
	{
		id: 434,
		alpha2: 'LY',
		alpha3: 'LBY',
		name: 'Libya',
		zipRegex: '^\\d{5}$',
		dialCode: 218
	},
	{
		id: 438,
		alpha2: 'LI',
		alpha3: 'LIE',
		name: 'Liechtenstein',
		zipRegex: '^\\d{4}$',
		dialCode: 423
	},
	{
		id: 440,
		alpha2: 'LT',
		alpha3: 'LTU',
		name: 'Lithuania',
		zipRegex: '^[Ll][Tt][- ]{0,1}\\d{5}$',
		dialCode: 370
	},
	{
		id: 442,
		alpha2: 'LU',
		alpha3: 'LUX',
		name: 'Luxembourg',
		zipRegex: '^\\d{4}$',
		dialCode: 352
	},
	{
		id: 446,
		alpha2: 'MO',
		alpha3: 'MAC',
		name: 'Macao',
		zipRegex: '',
		dialCode: 853
	},
	{
		id: 450,
		alpha2: 'MG',
		alpha3: 'MDG',
		name: 'Madagascar',
		zipRegex: '^\\d{3}$',
		dialCode: 261
	},
	{
		id: 454,
		alpha2: 'MW',
		alpha3: 'MWI',
		name: 'Malawi',
		zipRegex: '',
		dialCode: 265
	},
	{
		id: 458,
		alpha2: 'MY',
		alpha3: 'MYS',
		name: 'Malaysia',
		zipRegex: '^\\d{5}$',
		dialCode: 60
	},
	{
		id: 462,
		alpha2: 'MV',
		alpha3: 'MDV',
		name: 'Maldives',
		zipRegex: '^\\d{4,5}$',
		dialCode: 960
	},
	{
		id: 466,
		alpha2: 'ML',
		alpha3: 'MLI',
		name: 'Mali',
		zipRegex: '',
		dialCode: 223
	},
	{
		id: 470,
		alpha2: 'MT',
		alpha3: 'MLT',
		name: 'Malta',
		zipRegex: '^[A-Za-z]{3}\\s{0,1}\\d{4}$',
		dialCode: 356
	},
	{
		id: 584,
		alpha2: 'MH',
		alpha3: 'MHL',
		name: 'Marshall Islands',
		zipRegex: '^\\d{5}$',
		dialCode: 692
	},
	{
		id: 474,
		alpha2: 'MQ',
		alpha3: 'MTQ',
		name: 'Martinique',
		zipRegex: '^972\\d{2}$',
		dialCode: 596
	},
	{
		id: 478,
		alpha2: 'MR',
		alpha3: 'MRT',
		name: 'Mauritania',
		zipRegex: '',
		dialCode: 222
	},
	{
		id: 480,
		alpha2: 'MU',
		alpha3: 'MUS',
		name: 'Mauritius',
		zipRegex: '',
		dialCode: 230
	},
	{
		id: 175,
		alpha2: 'YT',
		alpha3: 'MYT',
		name: 'Mayotte',
		zipRegex: '^976\\d{2}$',
		dialCode: 262
	},
	{
		id: 484,
		alpha2: 'MX',
		alpha3: 'MEX',
		name: 'Mexico',
		zipRegex: '^\\d{5}$',
		dialCode: 52
	},
	{
		id: 583,
		alpha2: 'FM',
		alpha3: 'FSM',
		name: 'Micronesia',
		zipRegex: '^\\d{5}(-{1}\\d{4})$',
		dialCode: 691
	},
	{
		id: 498,
		alpha2: 'MD',
		alpha3: 'MDA',
		name: 'Moldova',
		zipRegex: '^[Mm][Dd][- ]{0,1}\\d{4}$',
		dialCode: 373
	},
	{
		id: 492,
		alpha2: 'MC',
		alpha3: 'MCO',
		name: 'Monaco',
		zipRegex: '^980\\d{2}$',
		dialCode: 377
	},
	{
		id: 496,
		alpha2: 'MN',
		alpha3: 'MNG',
		name: 'Mongolia',
		zipRegex: '^\\d{5}$',
		dialCode: 976
	},
	{
		id: 499,
		alpha2: 'ME',
		alpha3: 'MNE',
		name: 'Montenegro',
		zipRegex: '^\\d{5}$',
		dialCode: 382
	},
	{
		id: 500,
		alpha2: 'MS',
		alpha3: 'MSR',
		name: 'Montserrat',
		zipRegex: '^[Mm][Ss][Rr]\\s{0,1}\\d{4}$',
		dialCode: 1664
	},
	{
		id: 504,
		alpha2: 'MA',
		alpha3: 'MAR',
		name: 'Morocco',
		zipRegex: '^\\d{5}$',
		dialCode: 212
	},
	{
		id: 508,
		alpha2: 'MZ',
		alpha3: 'MOZ',
		name: 'Mozambique',
		zipRegex: '^\\d{4}$',
		dialCode: 258
	},
	{
		id: 104,
		alpha2: 'MM',
		alpha3: 'MMR',
		name: 'Myanmar',
		zipRegex: '^\\d{5}$',
		dialCode: 95
	},
	{
		id: 516,
		alpha2: 'NA',
		alpha3: 'NAM',
		name: 'Namibia',
		zipRegex: '^\\d{5}$',
		dialCode: 264
	},
	{
		id: 520,
		alpha2: 'NR',
		alpha3: 'NRU',
		name: 'Nauru',
		zipRegex: '',
		dialCode: 674
	},
	{
		id: 524,
		alpha2: 'NP',
		alpha3: 'NPL',
		name: 'Nepal',
		zipRegex: '^\\d{5}$',
		dialCode: 977
	},
	{
		id: 528,
		alpha2: 'NL',
		alpha3: 'NLD',
		name: 'Netherlands',
		zipRegex: '^\\d{4}\\s{0,1}[A-Za-z]{2}$',
		dialCode: 31
	},
	{
		id: 530,
		alpha2: 'AN',
		alpha3: 'ANT',
		name: 'Netherlands Antilles',
		zipRegex: 0
	},
	{
		id: 540,
		alpha2: 'NC',
		alpha3: 'NCL',
		name: 'New Caledonia',
		zipRegex: '^988\\d{2}$',
		dialCode: 687
	},
	{
		id: 554,
		alpha2: 'NZ',
		alpha3: 'NZL',
		name: 'New Zealand',
		zipRegex: '^\\d{4}$',
		dialCode: 64
	},
	{
		id: 558,
		alpha2: 'NI',
		alpha3: 'NIC',
		name: 'Nicaragua',
		zipRegex: '^\\d{5}$',
		dialCode: 505
	},
	{
		id: 562,
		alpha2: 'NE',
		alpha3: 'NER',
		name: 'Niger',
		zipRegex: '^\\d{4}$',
		dialCode: 227
	},
	{
		id: 566,
		alpha2: 'NG',
		alpha3: 'NGA',
		name: 'Nigeria',
		zipRegex: '^\\d{6}$',
		dialCode: 234
	},
	{
		id: 570,
		alpha2: 'NU',
		alpha3: 'NIU',
		name: 'Niue',
		zipRegex: 0,
		dialCode: 683
	},
	{
		id: 574,
		alpha2: 'NF',
		alpha3: 'NFK',
		name: 'Norfolk Island',
		zipRegex: '^\\d{4}$',
		dialCode: 672
	},
	{
		id: 580,
		alpha2: 'MP',
		alpha3: 'MNP',
		name: 'Northern Mariana Islands',
		zipRegex: '^\\d{5}$',
		dialCode: 1670
	},
	{
		id: 578,
		alpha2: 'NO',
		alpha3: 'NOR',
		name: 'Norway',
		zipRegex: '^\\d{4}$',
		dialCode: 47
	},
	{
		id: 512,
		alpha2: 'OM',
		alpha3: 'OMN',
		name: 'Oman',
		zipRegex: '^\\d{3}$',
		dialCode: 968
	},
	{
		id: 586,
		alpha2: 'PK',
		alpha3: 'PAK',
		name: 'Pakistan',
		zipRegex: '^\\d{5}$',
		dialCode: 92
	},
	{
		id: 585,
		alpha2: 'PW',
		alpha3: 'PLW',
		name: 'Palau',
		zipRegex: '^\\d{5}$',
		dialCode: 680
	},
	{
		id: 275,
		alpha2: 'PS',
		alpha3: 'PSE',
		name: 'Palestine, State of',
		zipRegex: 0,
		dialCode: 970
	},
	{
		id: 591,
		alpha2: 'PA',
		alpha3: 'PAN',
		name: 'Panama',
		zipRegex: '^\\d{6}$',
		dialCode: 507
	},
	{
		id: 598,
		alpha2: 'PG',
		alpha3: 'PNG',
		name: 'Papua New Guinea',
		zipRegex: '^\\d{3}$',
		dialCode: 675
	},
	{
		id: 600,
		alpha2: 'PY',
		alpha3: 'PRY',
		name: 'Paraguay',
		zipRegex: '^\\d{4}$',
		dialCode: 595
	},
	{
		id: 604,
		alpha2: 'PE',
		alpha3: 'PER',
		name: 'Peru',
		zipRegex: '^\\d{5}$',
		dialCode: 51
	},
	{
		id: 608,
		alpha2: 'PH',
		alpha3: 'PHL',
		name: 'Philippines',
		zipRegex: '^\\d{4}$',
		dialCode: 63
	},
	{
		id: 612,
		alpha2: 'PN',
		alpha3: 'PCN',
		name: 'Pitcairn',
		zipRegex: '^[Pp][Cc][Rr][Nn]\\s{0,1}[1][Zz]{2}$'
	},
	{
		id: 616,
		alpha2: 'PL',
		alpha3: 'POL',
		name: 'Poland',
		zipRegex: '^\\d{2}[- ]{0,1}\\d{3}$',
		dialCode: 48
	},
	{
		id: 620,
		alpha2: 'PT',
		alpha3: 'PRT',
		name: 'Portugal',
		zipRegex: '^\\d{4}$',
		dialCode: 351
	},
	{
		id: 630,
		alpha2: 'PR',
		alpha3: 'PRI',
		name: 'Puerto Rico',
		zipRegex: '^\\d{5}$',
		dialCode: 1
	},
	{
		id: 634,
		alpha2: 'QA',
		alpha3: 'QAT',
		name: 'Qatar',
		zipRegex: '',
		dialCode: 974
	},
	{
		id: 807,
		alpha2: 'MK',
		alpha3: 'MKD',
		name: 'Republic of North Macedonia',
		zipRegex: '^\\d{4}$',
		dialCode: 389
	},
	{
		id: 642,
		alpha2: 'RO',
		alpha3: 'ROU',
		name: 'Romania',
		zipRegex: '^\\d{6}$',
		dialCode: 40
	},
	{
		id: 643,
		alpha2: 'RU',
		alpha3: 'RUS',
		name: 'Russian Federation',
		zipRegex: '^\\d{6}$',
		dialCode: 7
	},
	{
		id: 646,
		alpha2: 'RW',
		alpha3: 'RWA',
		name: 'Rwanda',
		zipRegex: '',
		dialCode: 250
	},
	{
		id: 638,
		alpha2: 'RE',
		alpha3: 'REU',
		name: 'Réunion',
		zipRegex: '^974\\d{2}$',
		dialCode: 262
	},
	{
		id: 652,
		alpha2: 'BL',
		alpha3: 'BLM',
		name: 'Saint Barthélemy',
		zipRegex: '^97133$',
		dialCode: 590
	},
	{
		id: 654,
		alpha2: 'SH',
		alpha3: 'SHN',
		name: 'Saint Helena, Ascension and Tristan da Cunha',
		zipRegex: '^[Ss][Tt][Hh][Ll]\\s{0,1}[1][Zz]{2}$',
		dialCode: 290
	},
	{
		id: 659,
		alpha2: 'KN',
		alpha3: 'KNA',
		name: 'Saint Kitts and Nevis',
		zipRegex: '',
		dialCode: 1869
	},
	{
		id: 662,
		alpha2: 'LC',
		alpha3: 'LCA',
		name: 'Saint Lucia',
		zipRegex: '',
		dialCode: 1758
	},
	{
		id: 663,
		alpha2: 'MF',
		alpha3: 'MAF',
		name: 'Saint Martin (French part)',
		zipRegex: '^97150$',
		dialCode: 590
	},
	{
		id: 666,
		alpha2: 'PM',
		alpha3: 'SPM',
		name: 'Saint Pierre and Miquelon',
		zipRegex: '^97500$',
		dialCode: 508
	},
	{
		id: 670,
		alpha2: 'VC',
		alpha3: 'VCT',
		name: 'Saint Vincent and the Grenadines',
		zipRegex: '^[Vv][Cc]\\d{4}$',
		dialCode: 1784
	},
	{
		id: 882,
		alpha2: 'WS',
		alpha3: 'WSM',
		name: 'Samoa',
		zipRegex: '',
		dialCode: 685
	},
	{
		id: 674,
		alpha2: 'SM',
		alpha3: 'SMR',
		name: 'San Marino',
		zipRegex: '^4789\\d$',
		dialCode: 378
	},
	{
		id: 678,
		alpha2: 'ST',
		alpha3: 'STP',
		name: 'Sao Tome and Principe',
		zipRegex: '',
		dialCode: 239
	},
	{
		id: 682,
		alpha2: 'SA',
		alpha3: 'SAU',
		name: 'Saudi Arabia',
		zipRegex: '^\\d{5}(-{1}\\d{4})?$',
		dialCode: 966
	},
	{
		id: 686,
		alpha2: 'SN',
		alpha3: 'SEN',
		name: 'Senegal',
		zipRegex: '^\\d{5}$',
		dialCode: 221
	},
	{
		id: 688,
		alpha2: 'RS',
		alpha3: 'SRB',
		name: 'Serbia',
		zipRegex: '^\\d{5}$',
		dialCode: 381
	},
	{
		id: 690,
		alpha2: 'SC',
		alpha3: 'SYC',
		name: 'Seychelles',
		zipRegex: '',
		dialCode: 248
	},
	{
		id: 694,
		alpha2: 'SL',
		alpha3: 'SLE',
		name: 'Sierra Leone',
		zipRegex: '',
		dialCode: 232
	},
	{
		id: 702,
		alpha2: 'SG',
		alpha3: 'SGP',
		name: 'Singapore',
		zipRegex: '^\\d{2}$',
		dialCode: 65
	},
	{
		id: 534,
		alpha2: 'SX',
		alpha3: 'SXM',
		name: 'Sint Maarten (Dutch part)',
		zipRegex: '',
		dialCode: 1721
	},
	{
		id: 703,
		alpha2: 'SK',
		alpha3: 'SVK',
		name: 'Slovakia',
		zipRegex: '^\\d{5}\\s\\(\\d{3}\\s\\d{2}\\)$',
		dialCode: 421
	},
	{
		id: 705,
		alpha2: 'SI',
		alpha3: 'SVN',
		name: 'Slovenia',
		zipRegex: '^([Ss][Ii][- ]{0,1}){0,1}\\d{4}$',
		dialCode: 386
	},
	{
		id: 90,
		alpha2: 'SB',
		alpha3: 'SLB',
		name: 'Solomon Islands',
		zipRegex: '',
		dialCode: 677
	},
	{
		id: 706,
		alpha2: 'SO',
		alpha3: 'SOM',
		name: 'Somalia',
		zipRegex: '',
		dialCode: 252
	},
	{
		id: 710,
		alpha2: 'ZA',
		alpha3: 'ZAF',
		name: 'South Africa',
		zipRegex: '^\\d{4}$',
		dialCode: 27
	},
	{
		id: 239,
		alpha2: 'GS',
		alpha3: 'SGS',
		name: 'South Georgia and the South Sandwich Islands',
		zipRegex: '^[Ss][Ii][Qq]{2}\\s{0,1}[1][Zz]{2}$'
	},
	{
		id: 728,
		alpha2: 'SS',
		alpha3: 'SSD',
		name: 'South Sudan',
		zipRegex: '',
		dialCode: 211
	},
	{
		id: 724,
		alpha2: 'ES',
		alpha3: 'ESP',
		name: 'Spain',
		zipRegex: '^\\d{5}$',
		dialCode: 34
	},
	{
		id: 144,
		alpha2: 'LK',
		alpha3: 'LKA',
		name: 'Sri Lanka',
		zipRegex: '^\\d{5}$',
		dialCode: 94
	},
	{
		id: 729,
		alpha2: 'SD',
		alpha3: 'SDN',
		name: 'Sudan',
		zipRegex: '^\\d{5}$',
		dialCode: 249
	},
	{
		id: 740,
		alpha2: 'SR',
		alpha3: 'SUR',
		name: 'Suriname',
		zipRegex: '',
		dialCode: 597
	},
	{
		id: 744,
		alpha2: 'SJ',
		alpha3: 'SJM',
		name: 'Svalbard and Jan Mayen',
		zipRegex: '^\\d{4}$',
		dialCode: 47
	},
	{
		id: 752,
		alpha2: 'SE',
		alpha3: 'SWE',
		name: 'Sweden',
		zipRegex: '^\\d{3}\\s*\\d{2}$',
		dialCode: 46
	},
	{
		id: 756,
		alpha2: 'CH',
		alpha3: 'CHE',
		name: 'Switzerland',
		zipRegex: '^\\d{4}$',
		dialCode: 41
	},
	{
		id: 760,
		alpha2: 'SY',
		alpha3: 'SYR',
		name: 'Syrian Arab Republic',
		zipRegex: '',
		dialCode: 963
	},
	{
		id: 158,
		alpha2: 'TW',
		alpha3: 'TWN',
		name: 'Taiwan',
		zipRegex: '^\\d{5}$',
		dialCode: 886
	},
	{
		id: 762,
		alpha2: 'TJ',
		alpha3: 'TJK',
		name: 'Tajikistan',
		zipRegex: '^\\d{6}$',
		dialCode: 992
	},
	{
		id: 834,
		alpha2: 'TZ',
		alpha3: 'TZA',
		name: 'Tanzania',
		zipRegex: '',
		dialCode: 255
	},
	{
		id: 764,
		alpha2: 'TH',
		alpha3: 'THA',
		name: 'Thailand',
		zipRegex: '^\\d{5}$',
		dialCode: 66
	},
	{
		id: 626,
		alpha2: 'TL',
		alpha3: 'TLS',
		name: 'Timor-Leste',
		zipRegex: '',
		dialCode: 670
	},
	{
		id: 768,
		alpha2: 'TG',
		alpha3: 'TGO',
		name: 'Togo',
		zipRegex: 0,
		dialCode: 228
	},
	{
		id: 772,
		alpha2: 'TK',
		alpha3: 'TKL',
		name: 'Tokelau',
		zipRegex: '',
		dialCode: 690
	},
	{
		id: 776,
		alpha2: 'TO',
		alpha3: 'TON',
		name: 'Tonga',
		zipRegex: '',
		dialCode: 676
	},
	{
		id: 780,
		alpha2: 'TT',
		alpha3: 'TTO',
		name: 'Trinidad and Tobago',
		zipRegex: '^\\d{6}$',
		dialCode: 1868
	},
	{
		id: 788,
		alpha2: 'TN',
		alpha3: 'TUN',
		name: 'Tunisia',
		zipRegex: '^\\d{4}$',
		dialCode: 216
	},
	{
		id: 792,
		alpha2: 'TR',
		alpha3: 'TUR',
		name: 'Turkey',
		zipRegex: '^\\d{5}$',
		dialCode: 90
	},
	{
		id: 795,
		alpha2: 'TM',
		alpha3: 'TKM',
		name: 'Turkmenistan',
		zipRegex: '^\\d{6}$',
		dialCode: 993
	},
	{
		id: 796,
		alpha2: 'TC',
		alpha3: 'TCA',
		name: 'Turks and Caicos Islands',
		zipRegex: '(^[Tt][Kk][Cc][Aa]\\s{0,1}[1][Zz]{2}$)',
		dialCode: 1649
	},
	{
		id: 798,
		alpha2: 'TV',
		alpha3: 'TUV',
		name: 'Tuvalu',
		zipRegex: '',
		dialCode: 688
	},
	{
		id: 800,
		alpha2: 'UG',
		alpha3: 'UGA',
		name: 'Uganda',
		zipRegex: '',
		dialCode: 256
	},
	{
		id: 804,
		alpha2: 'UA',
		alpha3: 'UKR',
		name: 'Ukraine',
		zipRegex: '^\\d{5}$',
		dialCode: 380
	},
	{
		id: 784,
		alpha2: 'AE',
		alpha3: 'ARE',
		name: 'United Arab Emirates',
		zipRegex: '',
		dialCode: 971
	},
	{
		id: 826,
		alpha2: 'GB',
		alpha3: 'GBR',
		name: 'United Kingdom',
		zipRegex: '(^[A-Z]{1,2}\\d[A-Z\\d]? ?\\d[A-Z]{2}$)',
		dialCode: 44
	},
	{
		id: 581,
		alpha2: 'UM',
		alpha3: 'UMI',
		name: 'United States Minor Outlying Islands',
		zipRegex: '',
		dialCode: 1
	},
	{
		id: 840,
		alpha2: 'US',
		alpha3: 'USA',
		name: 'United States of America',
		zipRegex: '(^\\d{5}$)',
		dialCode: 1
	},
	{
		id: 858,
		alpha2: 'UY',
		alpha3: 'URY',
		name: 'Uruguay',
		zipRegex: '^\\d{5}$',
		dialCode: 598
	},
	{
		id: 860,
		alpha2: 'UZ',
		alpha3: 'UZB',
		name: 'Uzbekistan',
		zipRegex: '^\\d{3} \\d{3}$',
		dialCode: 998
	},
	{
		id: 548,
		alpha2: 'VU',
		alpha3: 'VUT',
		name: 'Vanuatu',
		zipRegex: '',
		dialCode: 678
	},
	{
		id: 862,
		alpha2: 'VE',
		alpha3: 'VEN',
		name: 'Venezuela',
		zipRegex: '^\\d{4}(\\s[a-zA-Z]{1})?$',
		dialCode: 58
	},
	{
		id: 704,
		alpha2: 'VN',
		alpha3: 'VNM',
		name: 'Viet Nam',
		zipRegex: '^\\d{6}$',
		dialCode: 84
	},
	{
		id: 92,
		alpha2: 'VG',
		alpha3: 'VGB',
		name: 'Virgin Islands (British)',
		zipRegex: '^[Vv][Gg]\\d{4}$',
		dialCode: 1284
	},
	{
		id: 850,
		alpha2: 'VI',
		alpha3: 'VIR',
		name: 'Virgin Islands (U.S.)',
		zipRegex: '^\\d{5}$',
		dialCode: 1340
	},
	{
		id: 876,
		alpha2: 'WF',
		alpha3: 'WLF',
		name: 'Wallis and Futuna',
		zipRegex: '^986\\d{2}$',
		dialCode: 681
	},
	{
		id: 732,
		alpha2: 'EH',
		alpha3: 'ESH',
		name: 'Western Sahara',
		zipRegex: '',
		dialCode: 212
	},
	{
		id: 887,
		alpha2: 'YE',
		alpha3: 'YEM',
		name: 'Yemen',
		zipRegex: '',
		dialCode: 967
	},
	{
		id: 894,
		alpha2: 'ZM',
		alpha3: 'ZMB',
		name: 'Zambia',
		zipRegex: '^\\d{5}$',
		dialCode: 260
	},
	{
		id: 716,
		alpha2: 'ZW',
		alpha3: 'ZWE',
		name: 'Zimbabwe',
		zipRegex: '',
		dialCode: 263
	},
	{
		id: 248,
		alpha2: 'AX',
		alpha3: 'ALA',
		name: 'Åland Islands',
		zipRegex: '^\\d{5}$',
		dialCode: 358
	}
];
